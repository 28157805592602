<template>
  <v-card
    width="400"
    class="mx-auto mt-5 address-card"
  >
    <v-card-text>
      <v-form
        v-model="isFormValid"
      >
        <v-text-field
          v-model="customer.email"
          label="email"
          prepend-icon="mdi-email"
          :rules="emailRules"
          required
        />
        <v-text-field
          v-model="customer.name"
          label="name"
          prepend-icon="mdi-account-circle"
          :rules="nameRules"
          required
        />
        <v-text-field
          v-model="customer.newPassword"
          :type="showPassword ? 'text' : 'password'"
          label="New Password"
          prepend-icon="mdi-lock"
          :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          :rules="passwordRules"
          required
          @click:append="showPassword = !showPassword"
        />
        <v-text-field
          v-model="newPasswordVerification"
          :type="showPasswordVerification ? 'text' : 'password'"
          label="Verify New Password"
          prepend-icon="mdi-lock"
          :append-icon="showPasswordVerification ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          :rules="[(this.customer.newPassword === this.newPasswordVerification) || 'Passwords must match']"
          @click:append="showPasswordVerification = !showPasswordVerification"
        />
      </v-form>
      <loader v-show="loader" />
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        class="form-btn"
        :disabled="!isFormValid"
        @click="submitForm"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import Loader from '@/components/Forms/Elements/Loader'
  import BasicForm from '@/mixins/BasicForm'
  import { requiredRule, emailRule, passwordRegexRule } from '@/store/formValidationRules'

  export default {
    name: 'AccountEditForm',
    components: {
      Loader,
    },
    mixins: [
      BasicForm,
    ],
    props: {
      customerData: Object,
      callbacks: Object,
    },
    data: () => ({
      isFormValid: false,
      loader: false,
      customer: {
        email: '',
        name: '',
        newPassword: '',
      },
      newPasswordVerification: '',
      showPassword: false,
      showPasswordVerification: false,

      nameRules: [
        requiredRule('Name is required.'),
      ],
      emailRules: [
        requiredRule('E-mail is required.'),
        emailRule(),
      ],
      passwordRules: [
        passwordRegexRule(true),
      ],
    }),
    methods: {
      ...mapActions('customer', ['updateCustomer']),
      ...mapGetters('customer', ['getCustomerData']),
      async submit (token) {
        const { email, name, newPassword } = this.customer
        const requestPayload = {
          Email: email,
          Name: name,
          Oid: this.getCustomerData().Oid,
          ReCaptchaResponse: token,
        }
        if (newPassword) {
          requestPayload.Password = newPassword
        }

        this.$store.dispatch('customer/updateCustomer', requestPayload)
          .then(() => {
            this.$store.dispatch('customer/getCustomerDetails')
            this.$store.dispatch('notificationAlerts/addNotification', {
              text: 'You account has been updated successfully',
              type: 'success',
            })
            if (this.callbacks.success) {
              this.callbacks.success()
            }
          })
          .finally(() => {
            this.loader = false
          })
      },
    },
    created () {
      this.customer = {
        ...this.customer,
        ...this.customerData,
      }
    },
  }
</script>

<style lang="scss" scoped >
  .address-card{
    margin-top: 0 !important;
    .v-card__text{
      padding: 20px 24px !important;
    }
  }

</style>
