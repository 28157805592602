<template>
  <div id="offer-calculation-box">
    <div class="box-main-content">
      <v-img
        :src="require('@/assets/icons/x_close.png')"
        class="close-modal-button"
        style="cursor: pointer"
        width="16"
        @click="$emit('close-event')"
      />
      <slot name="section-before" />
      <div class="text">
        {{ boxText }}
      </div>
      <div class="price">
        &euro;{{ price }}
      </div>
      <div
        v-if="submitButtonText"
        class="actions"
        align="center"
      >
        <v-btn
          id="create-order-button"
          class="primary"
          @click="$emit('button-clicked-event')"
        >
          {{ submitButtonText }}
        </v-btn>
      </div>
    </div>
    <slot name="section-after" />
  </div>
</template>

<script>
  export default {
    name: 'OfferCalculationBox',
    inheritAttrs: false,
    props: {
      price: [String, Number],
      boxText: {
        type: String,
        required: true,
      },
      submitButtonText: {
        type: String,
        required: false,
      },
    },
    emits: ['closeEvent', 'buttonClickedEvent'],
    data: () => ({
      showDialog: true,
    }),
    created () {
    },
  }
</script>

<style lang="scss" scoped>
  #offer-calculation-box {
    max-width: 400px;
    display: inline-block;
    background-color: $bgWhite09;
    padding-bottom: 0;
    position: relative;

    .box-main-content{
      padding: 20px;
      padding-top: 40px;
      .text{
        font-size: 33px;
        font-weight: 300;
        color: $goldairBlue;
        text-align: center;
      }

      .price{
        color: $goldairYellow;
        text-align: center;
        font-size: 41px;
        font-weight: bold;
        margin-top: 20px;
      }
      #create-order-button{
        margin-top: 20px;
      }
    }

    .close-modal-button{
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }
</style>
