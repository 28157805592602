const state = {
  disableScreen: false,
  infoComponentManuallyClosed: false,
  locale: null,
  appVersion: null,
}

const getters = {
  getDisableScreen (state) {
    return state.disableScreen
  },
  getInfoComponentManuallyClosed (state) {
    return state.infoComponentManuallyClosed
  },
  getLocale (state) {
    return state.locale
  },
  getAppVersion (state) {
    return state.appVersion
  },
}

const mutations = {
  SET_DISABLE_SCREEN (state, value) {
    state.disableScreen = value
  },
  SET_INFO_COMPONENT_MANUALLY_CLOSED (state, value) {
    state.infoComponentManuallyClosed = value
  },
  SET_LOCALE (state, value) {
    state.locale = value
  },
  SET_APP_VERSION (state, version) {
    state.appVersion = version
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
