<template>
  <v-card
    class="offer-form-component mx-auto mt-5"
  >
    <v-card-text>
      <slot name="before-form" />
      <v-form
        ref="offerForm"
        v-model="isFormValid"
        class="offer-form"
      >
        <v-item-group class="form-section section1">
          <div
            v-if="section1Title"
            class="section-title"
          >
            {{ section1Title }}
          </div>
          <div class="section-data">
            <v-item-group class="fields-group">
              <v-select
                v-model="offer.countryFrom"
                :items="getCountriesSelectOptions()"
                item-text="label"
                item-value="value"
                :label="$t('form.select-country-from')"
                required
                :rules="countryRules"
                validate-on-blur
              />
              <v-text-field
                v-model="offer.postcodeFrom"
                :label="$t('form.postcode-from')"
                :rules="postcodeRules"
                validate-on-blur
                required
              />
            </v-item-group>
            <v-item-group class="fields-group">
              <v-select
                v-model="offer.countryTo"
                :items="getCountriesSelectOptions()"
                :label="$t('form.select-country-to')"
                item-text="label"
                item-value="value"
                :rules="countryRules"
                validate-on-blur
                required
              />
              <v-text-field
                v-model="offer.postcodeTo"
                :label="$t('form.postcode-to')"
                :rules="postcodeRules"
                validate-on-blur
                required
              />
            </v-item-group>
            <v-item-group
              v-if="customerCanPrintOffer"
              class="fields-group"
              >
              <v-text-field
                v-model="offer.name"
                :label="$t('form.client-name')"
                validate-on-blur
                required
              />
              <v-text-field
                v-model="offer.telephone"
                :label="$t('form.client-telephone')"
                validate-on-blur
                required
              />
            </v-item-group>
          </div>
        </v-item-group>
        <v-item-group class="form-section section2">
          <div
            v-if="section2Title"
            class="section-title"
          >
            {{ section2Title }}
          </div>
          <div class="section-data">
            <transition-group name="slide-items">
              <div
                v-for="item in offer.items"
                :key="item.id"
              >
                <v-item-group
                  class="item-section"
                  :class="{'slideOut': item.slideOut}"
                >
                  <div
                    class="item-title"
                    @click="toggleItem(item.id)"
                  >
                    {{ item.title }}
                    <v-icon
                      v-show="item.show"
                      class="float-right expand-less"
                      color="white"
                    >
                      mdi-chevron-up
                    </v-icon>
                    <v-icon
                      v-show="!item.show"
                      class="float-right expand-less"
                      color="white"
                    >
                      mdi-chevron-down
                    </v-icon>
                    <v-icon
                      class="float-right delete"
                      :disabled="offer.items.length <= 1"
                      color="white"
                      @click="removeItemWithAnimation(item.id)"
                    >
                      mdi-trash-can-outline
                    </v-icon>
                  </div>
                  <v-expand-transition>
                    <div
                      v-show="item.show"
                      class="item-data"
                    >
                      <div class="item-data-inner">
                        <v-item-group class="fields-group package-group">
                          <v-item-group class="fields-group package-group">
                            <v-select
                            v-model="item.packagingType"
                            class="packaging-type-select"
                            :items="getPackagingTypesSelectOptions()"
                            :label="$t('form.select-packaging-type')"
                            item-text="label"
                            item-value="value"
                            :rules="packagingTypeRules"
                            validate-on-blur
                            @change="Initiate_Counter(item.id)"
                            required
                            hint=""
                          >
                            <template v-slot:item="{item}">
                              {{ item.label }}
                              <v-spacer />
                              <span><v-img
                                :src="item.icon"
                              /></span>
                            </template>
                          </v-select>
                          <info-component
                            class="packaging-tooltip"
                            color="primary"
                            >{{ $t('form.package-info-notification') }}</info-component>
                          </v-item-group>
                          <v-item-group
                            class="fields-group"
                            style="align-items: center;"
                            >
                            <v-btn
                              class="quantity primary"
                              @click="Remove_Qty(item.id)"
                              :disabled="item.itemQty <= 1 || item.itemQty === undefined"
                              >
                              -
                            </v-btn>
                            <v-text-field
                              v-model="item.itemQty"
                              class="quantity-field"
                              :label="$t('quantity')"
                              readonly
                            />
                            <v-btn
                              class="quantity primary"
                              @click="Add_Qty(item.id)"
                              :disabled="item.itemQty >= maxAllowedItems || item.itemQty === undefined || maxCounter >= maxAllowedItems"
                              >
                              +
                            </v-btn>
                          </v-item-group>
                        </v-item-group>
                        <v-item-group class="fields-group">
                          <v-text-field
                            v-model="item.length"
                            :label="$t('length')"
                            :rules="lengthRules"
                            required
                          />
                          <v-text-field
                            v-model="item.width"
                            :label="$t('width')"
                            :rules="widthRules"
                          />
                        </v-item-group>
                        <v-item-group class="fields-group">
                          <v-text-field
                            v-model="item.height"
                            :label="$t('height')"
                            :rules="heightRules"
                            required
                          />
                          <v-text-field
                            v-model="item.weight"
                            :label="$t('weight')"
                            :rules="weightRules"
                            required
                          />
                        </v-item-group>
                      </div>
                    </div>
                  </v-expand-transition>
                </v-item-group>
              </div>
            </transition-group>
          </div>
        </v-item-group>
      </v-form>
      <div
        v-show="loader"
        class="loader-wrapper"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </div>

      <v-dialog
        v-model="showDialog"
        class="offer-calculation-box-dialog"
        width="unset"
      >
        <offer-calculation-box
          :price="calculatedCost"
          :box-text="$t('form.quote-header')"
          @close-event="showDialog = false"
        >
          <template v-slot:section-after>
            <div
              class="action-buttons-wrapper"
              style="margin-top: 20px;text-align: center;background-color: #dedede;padding: 20px 0;"
            >
              <a
                v-ripple
                class=" button save-offer-btn"
                :class="{ 'can-print-offer': customerCanPrintOffer}"
                style="display: inline; font-size: 15px"
                @click="saveOfferAction()"
              >
                <v-icon color="primary">mdi-content-save-outline</v-icon> {{ $t('form.save-offer') }}
              </a>
              <a
                v-if="customerCanPrintOffer"
                v-ripple
                class="action-link button print-offer-btn"
                style="display: inline;margin-left: 20px; font-size: 15px"
                @click="printOffer()"
              >
                <v-icon color="primary">mdi-printer-settings</v-icon> {{ $t('form.print-offer') }}
              </a>
            </div>
          </template>
        </offer-calculation-box>
        <loader v-show="saveOfferLoader" />
      </v-dialog>
      <slot name="after-form" />
    </v-card-text>
    <v-card-actions>
      <a
        class="add-item-button"
        :class="{disabled: offer.items.length >= maxAllowedItems || maxCounter >= maxAllowedItems}"
        @click="Add_Item()"
      >
        <v-img
          :src="require('@/assets/icons/archive-plus-outline.png')"
          class="image"
        /><span class="text">{{ $t('form.add-item') }}</span>
      </a>
      <v-spacer />
      <v-btn
        class="calculate-offer-btn form-btn"
        color="primary"
        @click="saveOffer = false; submitForm(true)"
      >
        {{ $t('form.get-quote') }}
      </v-btn>
      <v-btn
        v-if="allowMakeOrder"
        color="success"
        @click="makeOrder(offer.oid)"
      >
        Make Order
      </v-btn>
      <slot name="button-slot" />
    </v-card-actions>
    <!-- todo: to print section idanika prepei na ginei entelws automato,
            dld na mhn kanw click apo to current component alla na einai eswteriko tou Print/OfferPrint.vue.
            egw apla 8a prepei na pernaw ta  data -->
    <vue-html2pdf
      v-if="customerCanPrintOffer"
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="export"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :html-to-pdf-options="{ htmlToPdfOptions: { margin: 0, filename: 'new-offer.pdf', image: { type: 'jpeg', quality: 0.98 }, enableLinks: false, html2canvas: { scale: 1, useCORS: true }, jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait' }, } }"
    >
      <section slot="pdf-content">
        <offer-print
          v-if="printData !== null"
          :print-data="printData"
        />
      </section>
    </vue-html2pdf>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { authComputed } from '@/store/helpers'
  import offerFormMixin from '@/mixins/OfferFormMixin'
  import OfferCalculationBox from '@/components/OfferCalculationBox'
  import Loader from './Elements/Loader'
  import { requiredRule, requiredRule2 } from '@/store/formValidationRules'
  import OfferPrint from '@/components/Print/OfferPrint'
  import InfoComponent from '@/components/app/InfoComponent.vue'
  import VueHtml2pdf from 'vue-html2pdf'
  import Vue from 'vue'
  import i18n from '@/i18n'

  export default {
    name: 'OfferForm',
    components: {
      Loader,
      OfferCalculationBox,
      OfferPrint,
      InfoComponent,
      VueHtml2pdf,
      i18n,
    },
    mixins: [
      offerFormMixin,
    ],
    props: {
      oid: [Number, String],
      offerData: Object,
      countryFrom: {
        type: String,
        required: false,
      },
      countryTo: {
        type: String,
        required: false,
      },
      method: {
        type: String,
        required: true,
      },
      addItemText: {
        type: String,
        default: '',
      },
      calculateOffer: {
        type: String,
        default: '',
      },
      section1Title: {
        type: String,
      },
      section2Title: {
        type: String,
      },
      allowMakeOrder: {
        type: Boolean,
        default: false,
      },
      callbacks: {
        type: Object,
        default: () => ({}),
      },
      clientTelephone: {
        type: String,
        required: false,
      },
    },
    data: () => ({
      isFormValid: false,
      lastItemId: 0,
      itemsArray: [],
      calculatedCost: null,
      showDialog: false,
      saveOffer: false,
      counter: null,
      maxCounter: 1,
      saveOfferLoader: false,
      printData: null,
      offer: {
        oid: null,
        name: '',
        title: '',
        email: '',
        telephone: '',
        countryFrom: null,
        postcodeFrom: null,
        countryTo: null,
        postcodeTo: null,
        clientTelephone: null,
        clientName: null,
        items: [],
      },
      formMeta: {},
      countryRules: [
        requiredRule('Country is required.'),
      ],
      postcodeRules: [
        requiredRule('Postcode is required.'),
      ],
      packagingTypeRules: [
        requiredRule('Packaging type is required.'),
      ],
      widthRules: [
        requiredRule('Width is required.'),
        // requiredRule2('rrrrrrrrr'),
      ],
      lengthRules: [
        requiredRule('Length is required.'),
      ],
      weightRules: [
        requiredRule('Weight is required'),
      ],
      heightRules: [
        requiredRule('Height is required.'),
      ],
    }),
    computed: {
      ...authComputed,
      ...mapGetters('orders', ['getOfferByOid']),
      ...mapGetters('apiGeneric', ['getCountriesSelectOptions', 'getPackagingTypesSelectOptions2', 'getPackagingTypeNameById', 'getCountryDisplayNameByOid']),
      ...mapGetters('customer', ['customerCanPrintOffer']),
      formTitle: function () {
        return 'Offer #' + this.offer.Oid
      },
      maxAllowedItems: () => { return Vue.prototype.$offerItemsLimit },
    },
    created () {
      if (this.countryFrom) {
        this.offer.countryFrom = parseInt(this.countryFrom)
      }
      if (this.countryTo) {
        this.offer.countryTo = parseInt(this.countryTo)
      }
    },
    methods: {
      ...mapGetters('login', ['getOfferToSave']),
      Add_Item () {
        if (this.maxCounter <= this.maxAllowedItems) {
          this.maxCounter++
          this.counter = 1
          this.addItem()
        }
      },
      Initiate_Counter (id) {
        this.counter = 1
        this.offer.items.find(x => x.id === id).itemQty = 1
      },
      Add_Qty (id) {
        if (this.offer.items.find(x => x.id === id).itemQty < this.maxAllowedItems) {
          this.counter = ++this.offer.items.find(x => x.id === id).itemQty
        } else {
          this.counter = this.maxAllowedItems
        }
        this.maxCounter++
        this.$forceUpdate()
      },
      Remove_Qty (id) {
        if (this.offer.items.find(x => x.id === id).itemQty > 1) {
          this.counter = --this.offer.items.find(x => x.id === id).itemQty
        } else {
          this.counter = 1
        }
        this.maxCounter--
        this.$forceUpdate()
      },
      submit (token) {
        if (this.$refs.offerForm.validate()) {
          this.counter = null
          this.loader = false
          const requestPayload = {
            Commit: this.saveOffer,
            ClientName: this.offer.name,
            ClientEmail: this.offer.email,
            ClientTelephone: this.offer.telephone,
            CountryFrom: {
              Oid: this.offer.countryFrom,
            },
            PostCodeFrom: this.offer.postcodeFrom.replace(/\s/g, ''),
            CountryTo: {
              Oid: this.offer.countryTo,
            },
            PostCodeTo: this.offer.postcodeTo.replace(/\s/g, ''),
            // ClientName: this.offer.clientName,
            // ClientTelephone: this.offer.clientTelephone,
            Items: [],
            ReCaptchaResponse: token,
          }

          // ean einai create offer tote den exei oid
          if (this.offer.oid) {
            requestPayload.Oid = this.offer.oid
          }

          for (let i = 0; i < this.offer.items.length; i++) {
            requestPayload.Items.push({
              PackagingType: {
                Oid: this.offer.items[i].packagingType,
              },
              Width: this.offer.items[i].width,
              Height: this.offer.items[i].height,
              Length: this.offer.items[i].length,
              Weight: this.offer.items[i].weight,
              Quantity: this.offer.items[i].itemQty,
            })
          }

          let action = ''
          if (this.method === 'create') {
            action = 'orders/createOffer'
          } else if (this.method === 'update') {
            action = 'orders/updateOffer'
          }
          const self = this
          this.makeRequest(action, requestPayload)
            .then((responseData) => {
              if (responseData) {
                const orderData = responseData.data
                if (responseData.status !== 200) {
                  self.calculatedCost = null
                  self.showDialog = false
                  self.$store.dispatch('notificationAlerts/addNotification', {
                    text: orderData.CalculatedCostIssues,
                    type: 'error',
                  },
                  )
                } else if (this.saveOffer && orderData.Oid < 0) {
                  self.$store.dispatch('notificationAlerts/addNotification', {
                    text: 'Error occurred. Could not save offer.',
                    type: 'error',
                  },
                  )
                  window.scroll({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                  })
                } else {
                  // fetch offers locally again
                  self.showDialog = true
                  self.calculatedCost = orderData.Total
                  if (this.saveOffer) {
                    self.$store.dispatch('orders/action_getOfferById', { offerId: orderData.Oid })
                    self.$store.dispatch('notificationAlerts/addNotification', {
                      text: 'Offer saved successfully',
                      type: 'success',
                    },
                    )
                    self.$router.push({ name: 'Account/MyOffers' })
                  }
                }
              }
            })
            .finally(() => {
              this.loader = false
              this.saveOfferLoader = false
            })
        }
      },
      makeOrder: function (oid) {
        this.$router.push({ name: 'Order/Create', params: { oid: oid } })
      },
      printOffer: function () {
        this.printData = { ...this.offer }
        this.printData.countryFrom = this.getCountryDisplayNameByOid(this.offer.countryFrom)
        this.printData.countryTo = this.getCountryDisplayNameByOid(this.offer.countryTo)
        this.printData.Total = this.calculatedCost

        const items = { ...this.offer.items }
        for (let i = 0; i < items.length; i++) {
          items[i].packagingType = this.getPackagingTypeNameById(items[i].packagingType)
        }
        this.printData.items = items

        this.$refs.html2Pdf.generatePdf()
      },
      saveOfferAction: function () {
        if (this.loggedIn) {
          this.saveOffer = true
          this.saveOfferLoader = true
          this.submitForm(true)
        } else {
          this.$store.commit('login/SET_SHOW_LOGIN', true)
          this.$store.commit('login/SET_OFFER_TO_SAVE', {
            offer: this.offer,
            email: null,
          })
        }
      },
      removeItemWithAnimation (id) {
        for (let i = 0; i < this.offer.items.length; i++) {
          if (this.offer.items[i].id === id) {
            this.maxCounter -= this.offer.items[i].itemQty !== undefined ? this.offer.items[i].itemQty : 1
            this.offer.items[i].slideOut = true
            const self = this
            setTimeout(function () {
              self.removeItem(id)
            }, 100)
            break
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.quantity {
  font-size: 25px;
  width: 30px;
  height: 35px !important;
  min-width: 0 !important;
  border-radius: 30%;
}
.quantity-field {
  width: 65px;
  margin: 0 25px;
  pointer-events: none;
}
.offer-form-component{
  width: 100%;
  background-color: $bgTransparent;
  box-shadow: none !important;
  border-radius: 0;
  > .v-card__text{
    padding: 0;
  }
  > .v-card__actions{
    background-color: $bgWhite09;
    padding: 20px 30px;
  }
  .offer-form{
    background-color: $bgTransparent;

    .form-section{
      .v-select{
        border-radius: 0 !important;
        fieldset{
          border-width: 0 !important;
          border-bottom: 1px solid currentColor !important;
        }
      }
      .section-title {
        color: #fff;
        background-color: $goldairYellow;
        padding: 10px;
        font-size: 15px;
        font-weight: bold;
      }
      &.section1{
        background-color: #fff;
      }
      &.section2{
        .section-data{
          padding: 0;
          .item-title{
            color: #fff;
            background-color: $goldairBlue3;
            font-size: 15px;
            padding: 10px;
            font-weight: 500;
            cursor: pointer;
            .v-icon{
              cursor: pointer;
              &.delete{
                margin-right: 10px;
                &.v-icon--disabled{
                  color: rgba(255, 255, 255, 0.38) !important;
                }
              }
            }
          }
          .item-data{
            .item-data-inner{
              padding: 30px;
            }
            background-color: $bgWhite09;
          }
        }
      }
      .section-data{
        padding: 30px;
        &.item-section{
          margin-bottom: 20px;
        }
        .fields-group{
          display: flex;
          flex-direction: row;
          padding: 5px;
          .v-input {
            flex-basis: 50% !important;
          }
          .v-select, .v-input:first-child{
            padding-right: 10px;
          }
          &.package-group{
            .info-component{
              display: inline-block;
              vertical-align: middle;
              width: 22px;
              margin-right: 10px;
              position: relative;
            }
            .packaging-type-select{
              display: inline-block;
              vertical-align: middle;
              width: calc(100% - 22px);
            }
          }
        }
      }
    }
  }
  .add-item-button{
    position: relative;
    .image {
      display: inline-block;
      width: 25px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    .text {
      padding-left: 30px;
      font-size: 13px;
    }
  }
}
.loader-wrapper{
  width: 100%;
  height: 100%;
  background-color: $bgWhite08;
  z-index: 9999;
  position: absolute;
  top: 0;
  right: 0;
  .v-progress-circular{
    height: 32px;
    width: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.slide-items-enter{
  transform: translateY(-100px);
}
.slide-items-enter-active,
.slide-items-leave-active {
  transition: transform 0.5s ease-in-out;
}

.slide-items-leave{
  transform: translateY(-100px);
}

.calculate-offer-btn{
  padding:0 30px !important;
}
.save-offer-btn{
  &.can-print-offer:after{
    display: inline-block;
    content: '';
    background-color: rgb(40, 82, 132);
    width: 1px;
    height: 12px;
    margin-left: 20px;
  }
}
</style>

<style scoped >
.slideOut {
  animation-duration: 1s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
  transform: translateX(0);
  }
  70% { transform: translateX(-70%); }
  to {
    transform: translateX(-150%);
  }
}
</style>

<style lang="scss">
.packaging-tooltip > .dn-tooltip {
  transform: translate(45%, 100%) !important;
}
.package-group{
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
  .info-component{
    z-index: unset;
    button.info-icon{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      font-size: 18px !important;
    }
    .dn-tooltip{
      background-color: $goldairBlue;
      color: #fff !important;
      font-size: 13px;
      top: -235px;
      right: auto;
      left: -570px;
      bottom: auto;
      max-width: 600px;
      z-index: 10;
      &:after{
        background-color: $goldairBlue;
        bottom: -5px;
        top: auto;
        left: auto;
        right: 15px;
      }
    }
  }
}
</style>
