const state = {
  showLogin: false,
  offerToSave: null,
}

const getters = {
  getShowLogin (state) {
    return state.showLogin
  },
  getOfferToSave (state) {
    return state.offerToSave
  },
}

const mutations = {
  SET_SHOW_LOGIN (state, value) {
    state.showLogin = value
  },
  SET_OFFER_TO_SAVE (state, data) {
    if (data) {
      state.offerToSave = data
      return true
    }
    return false
  },

  CLEAR_OFFER_TO_SAVE (state) {
    state.offerToSave = null
    return true
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}
