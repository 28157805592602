<template>
<div id="pdf-content-div">
    <div class="print-header-logo"> {{ selectedLanguage }}
      <img
        src="@/assets/images/goldair_cargo_blue.png"
        alt="Goldair Logo Blue"
      />
    </div>
    <v-divider></v-divider>
    <div class="print-header-content">
      <div class="header-content-left">
        <div class="basic-info">
          <div class="info-box">
            <span class="label">{{ $t('print.to-client-name') }}:</span>
            <span class="value">{{ printData.clientName }}</span>
          </div>
          <div class="info-box">
            <span class="label">{{ $t('print.to-client-telephone') }}:</span>
            <span class="value">{{ printData.clientTelephone }}</span>
          </div>
          <div class="info-box invisible">
            <span class="label">{{ $t('print.to-client-telephone') }}:</span>
            <span class="value">{{ printData.clientTelephone }}</span>
          </div>
          <div class="info-box invisible">
            <span class="label">{{ $t('print.to-client-telephone') }}:</span>
            <span class="value">{{ printData.clientTelephone }}</span>
          </div>
          <div class="info-box right-aligned">
            <span class="label">{{ $t('print.date-label') }}:</span>
            <span class="value">{{ offerModifiedDateForPrint }}</span>
          </div>
        </div>
      </div>
      <div class="header-content-title">
        <h2>{{ $t('print.page-title') }}</h2>
      </div>
        <p
          v-if="selectedLanguage === 'el'"
          class="color-black"
          >Σας ευχαριστούμε που επιλέξατε τις υπηρεσίες ηλεκτρονικού εμπορίου της Goldair Cargo Sa. Σας αποστέλλουμε το κόστος της μεταφοράς για τα στοιχεία της αποστολής που εισάγατε στο on line portal.</p>
          <p
          v-if="selectedLanguage === 'en'"
          class="color-black"
          >Thank you for choosing Goldair Cargo Sa e-commerce services. We are sending you the shipping cost for the shipping details you entered on our online portal.</p>
          <br /><br />
    </div>
    <v-card
      class="preview-card"
      v-bind="$attrs"
    >
      <v-card-title>
        <strong>{{ title }}</strong>
      </v-card-title>
      <v-card-text>
        <div class="basic-info">
          <div class="info-box">
            <span class="label">Country From:</span>
            <span class="value">{{ printData.countryFrom }}</span>
          </div>
          <div class="info-box">
            <span class="label">Postcode From:</span>
            <span class="value">{{ printData.postcodeFrom }}</span>
          </div>
          <div class="arrow-box">
            <v-img
              src="@/assets/icons/arrow.png"
              width="25%"
            />
          </div>
          <div class="info-box">
            <span class="label">Country To:</span>
            <span class="value">{{ printData.countryTo }}</span>
          </div>
          <div class="info-box">
            <span class="label">Postcode To:</span>
            <span class="value">{{ printData.postcodeTo }}</span>
          </div>
        </div>
        <div class="items-wrapper">
          <div
            v-for="(item, index) in printData.items"
            :key="index"
            class="offer-item"
          >
            <v-card
              class="item-card"
            >
              <v-card-title>{{ item.title }}</v-card-title>
              <v-card-text>
                <div class="info-box">
                  <span class="label">Packaging Type:</span>
                  <span class="value">{{ $t('packaging-types.'+item.PackagingType.Name)+' ('+item.Quantity+')' }}</span>
                </div>
                <div class="info-box">
                  <span class="label">Width (cm):</span>
                  <span class="value">{{ item.width }}</span>
                </div>
                <div class="info-box">
                  <span class="label">Height (cm):</span>
                  <span class="value">{{ item.height }}</span>
                </div>
                <div class="info-box">
                  <span class="label">Length (cm):</span>
                  <span class="value">{{ item.length }}</span>
                </div>
                <div class="info-box">
                  <span class="label">Weight (kg):</span>
                  <span class="value">{{ item.weight }}</span>
                </div>
              </v-card-text>
            </v-card>
          </div>
        </div>
        <div class="price-wrapper">
          <span class="label">Total Cost:</span> <span class="price">&euro;{{ printData.Total }} </span>
          <span class="vat">+VAT(24%)</span>
        </div>
      </v-card-text>
    </v-card>
    <v-divider></v-divider>
    <div
      v-if="selectedLanguage === 'el'"
      class="print-terms color-black"
      >
      <h5>ΟΡΟΙ ΣΥΝΕΡΓΑΣΙΑΣ "GOLDAIR CARGO A.Ε."</h5><br />
      <p>Οι συγκεκριμένοι όροι αφορούν το ηλεκτρονικό κατάστημα πωλήσεων υπηρεσιών ομαδικών μεταφορών της Goldair Cargo Sa.<br /><br />H συγκεκριμένη υπηρεσία προσφέρεται αποκλειστικά σε εμπορικές συναλλαγές (B2B) και αφορά μεταφορές αγαθών με ανώτατο αριθμό συσκευασιών τις 4. Δεν γίνονται αποδεκτά εμπορεύματα που προέρχονται από αγορές μέσω διαδικτύου, επικίνδυνα ή εύφλεκτα εμπορεύματα, εύθραυστα ή ευπαθή εμπορεύματα, προσωπικά είδη και έπιπλα, μεταχειρισμένα είδη, τιμαλφή, έργα τέχνης και εμπορεύματα σε συνθήκες ελεγχόμενης θερμοκρασίας. Οι ανώτατες διαστάσεις ανά συσκευασία θα πρέπει να είναι 2,40 x 2,40 x 2,20 μέτρα. ( Μ x Π x Υ ).»</p>
      <p>- Οι παραπάνω αναφερόμενες τιμές βασίζονται στο τιμολογητέο βάρος των αποστολών με σχέση όγκου βάρους 1/3 (1 κυβικό μέτρο = 333 κιλά, για στοιβάσιμα εμπορεύματα, 1 LDM (Μέτρο φορτηγού) = 1750 κιλά για μη στοιβάσιμα εμπορεύματα).</p>
      <p>- Στις ανωτέρω αναφερόμενες τιμές ναύλων ΔΕΝ συμπεριλαμβάνεται ο εκάστοτε ισχύων ΦΠΑ (24%).</p>
      <p>- Οι παραπάνω τιμές βασίζονται στις ισχύουσες, κατά τον χρόνο της προσφοράς, τιμές υγρών καυσίμων, διοδίων, εισιτηρίων ferry boats κλπ. Οποιαδήποτε αξιοσημείωτη μεταβολή/ αύξηση των παραγόντων αυτών, θα επιφέρει και αντίστοιχη τροποποίηση στις τιμές της δοθείσας προσφοράς μας.</p>
      <p>- Η εταιρία μας ενεργεί σε κάθε μεταφορά ως παραγγελιοδόχος μεταφοράς. Οι διεθνείς οδικές μεταφορές των εμπορευμάτων που αναλαμβάνουμε, εφόσον δεν υπάρχει διαφορετική έγγραφη συμφωνία μεταξύ των μερών, διέπονται από τις διατάξεις και τους Όρους της «Σύμβασης της Γενεύης’ για την διεθνή μεταφορά εμπορευμάτων οδικώς (C.M.R)» (Νόμος 559/1977), τους ‘’Γενικούς Όρους διαμεταφοράς εμπορευμάτων’’ του Συνδέσμου Επιχειρήσεων Διαμεταφοράς και επιχειρήσεων Logistics Ελλάδος (ΣΥΝΔΔΕ&L) και τους όρους της Διεθνούς συνθήκης για την μεταφορά επικινδύνων εμπορευμάτων (ADR) (Νόμος 1741/1987 όπως τροποποιήθηκε και ισχύει).</p>
      <p>- Σημείωση για αποζημιώσεις CMR: Η ευθύνη της εταιρίας μας, για ζημιές ή απώλειες στα μεταφερόμενα εμπορεύματα ιδιοκτησίας σας, προσδιορίζεται βάσει της Διεθνούς Σύμβασης CMR σε 8,33 SDR περίπου ανά κιλό μικτού βάρους ζημιωθέντος ή απολεσθέντος εμπορεύματος με απαλλαγή για το ποσό των 300€ και εφαρμόζεται μετά από σχετική έγγραφη αναγγελία ζημίας εντός 48 ωρών από την παραλαβή προς τους υπευθύνους της εταιρίας μας. Στην αναγγελία αυτή θα πρέπει να προσδιορίζεται με σαφήνεια και επαρκώς το είδος και το ύψος της ζημίας, φθοράς ή απώλειας. Επίσης σε πιθανό Αίτημα αποζημίωσης πρέπει να προϋπάρχουν και οι σχετικές παρατηρήσεις στο παραστατικό έγγραφο παράδοσης (Δελτίο Αποστολής ή φορτωτική CMR). Σε περίπτωση που η αξία των μεταφερόμενων εμπορευμάτων ανά κιλό υπερβαίνει τα 10,00 € θα πρέπει να μας δηλωθεί εγγράφως, έτσι ώστε να προβούμε σε ξεχωριστή ασφάλιση. H ασφάλεια CMR σε καμία περίπτωση δεν υποκαθιστά την Ασφάλιση Μεταφοράς ή Αποθήκευσης των εμπορευμάτων. Σε οποιαδήποτε αντίθετη περίπτωση, το αίτημα αποζημίωσης δεν θα γίνεται αποδεκτό από την εταιρία μας.</p>
      <p>- Η οποιαδήποτε διαφοροποίηση / αλλαγή των ποσοτικών ή και άλλων βασικών στοιχείων της/των αποστολής/ων (αλλαγή τοποθεσίας, διαστάσεων, συσκευασίας, βάρους κλπ.) από τα αντίστοιχα στοιχεία της ανάθεσης, κατά την διάρκεια της φόρτωσης, θα επιφέρει τις αντίστοιχες αυξήσεις στις προσφερθείσες τιμές και στις τελικές χρεώσεις μας. Για την περίπτωση που οι μεταγενέστερες αλλαγές αυτές είναι σημαντικές και επιφέρουν υπέρμετρη δυσχέρεια στην ομαλή εκτέλεση της παραγγελίας, διατηρούμε το δικαίωμα να αρνηθούμε την φόρτωση.</p>
      <p>- Η ανάθεση φόρτωσης συγκεκριμένης ποσότητας ή/και όγκου εμπορευμάτων ή/και καταληφθέντος χώρου φορτηγού , είναι δεσμευτική για τον πελάτη ως ελάχιστη χρέωση από την εταιρία μας.</p>
      <p>- Τα εμπορεύματα στοιβάζονται επί των Μέσων μεταφοράς, εφόσον δεν ορίζει εγγράφως κάτι διαφορετικό ο εντολέας μας και διακινούνται σύμφωνα με τους Διεθνείς/Εθνικούς κανονισμούς Ασφαλούς Μεταφοράς και τις υποδείξεις του φορτωτή οίκου ή εντολέα. Εάν ο υποδεικνυόμενος από τον φορτωτή οίκο τρόπος στοιβασίας είναι διαφορετικός από αυτόν που αρχικά υποδείχτηκε από τον πελάτη ή/και δεν είναι ο ενδεδειγμένος και ο σύμφωνος με τους Κανονισμούς Ασφαλούς Μεταφοράς εμπορευμάτων, δικαιούμαστε να αρνηθούμε την μεταφορά. Σε περίπτωση που πραγματοποιηθεί η μεταφορά – με ευθύνη του φορτωτή ή εντολέα μας – έχουμε το δικαίωμα να τροποποιήσουμε (αυξήσουμε) τις τιμές ναύλων λόγω αλλαγής των τιμολογητέων βαρών.</p>
      <p>- Οι προσφερόμενες από την εταιρία μας διαμεταφορικές υπηρεσίες πραγματοποιούνται με βάση τα στοιχεία που μας δίνονται, συνεπώς ουδεμία ευθύνη υπέχουμε εάν αυτά είναι ελλιπή, ασαφή ή/και δεν ανταποκρίνονται στην πραγματικότητα.</p>
      <p>- Ο φορτωτής οίκος ή/και εντολέας είναι ο αποκλειστικός υπεύθυνος για την προμήθεια και την ορθότητα όλων των απαραίτητων συνοδευτικών της μεταφοράς εγγράφων (εμπορικά τιμολόγια, ακρίβεια των στοιχείων που δηλώνονται για την σύνταξη του C.M.R , Packing lists κλπ.).</p>
      <p>- Η εταιρία μας δεν φέρει καμία ευθύνη εάν οι προμηθευτές των πελατών της παραδώσουν εμπορεύματα στους ανταποκριτές μας στο εξωτερικό, χωρίς προηγούμενη συνεννόηση ή ενημέρωσή μας.</p>
      <p>- Η εξόφληση των τιμολογίων μας, εφόσον δεν υπάρχει διαφορετική έγγραφη συμφωνία των μερών ή ειδική ρήτρα στη μεταφορά, θα γίνεται ”τοις μετρητοίς’’ από τον εντολέα κατά την παράδοση της αποστολής ή/και του τιμολογίου μας. Συμψηφισμός κομίστρων σε περίπτωση ζημίας ή απώλειας εμπορευμάτων δεν είναι αποδεκτός και δεν επιτρέπεται.</p>
      <p>- Σε περιπτώσεις απεργιών των κλάδων που εμπλέκονται με οποιοδήποτε τρόπο στην διαδικασία της διαμεταφοράς ή και άλλων γεγονότων που συνιστούν λόγους ανωτέρας βίας, δεν φέρουμε καμία ευθύνη έναντι του πελάτη ή τρίτων για τυχόν καθυστερήσεις ή προσωρινή αδυναμία παράδοσης των εμπορευμάτων.</p>
      <p>- Η αποδοχή της προσφοράς μας εκ μέρους του πελάτη σημαίνει αυτόματα την ανεπιφύλακτη αποδοχή όλων των ανωτέρω όρων συνεργασίας μεταξύ των μερών.</p><br /><br />
      <h5>Με εκτίμηση,</h5>
      <h5>GOLDAIR CARGO Α.Ε.</h5><br /><br /><br />
      <p class="center-aligned small-text">Goldair Cargo S.A<br />Head Office<br />Thesi Rykia, Gr 19300 Aspropyrgos Tel +302111804200, Fax +302111804249</p>
    </div>
    <div
      v-if="selectedLanguage === 'en'"
      class="print-terms color-black"
      >
        <h5>TERMS OF COOPERATION WITH "GOLDAIR CARGO S.A."</h5><br />
        <p>- The above rates do NOT include the VAT rate applicable at any time (24%).</p>
        <p>- Our offer does not apply to flammable, fragile & perishable goods, unless we have declared in writing that it concerns such merchandise.</p>
        <p>- The aforesaid rates are based on the chargeable weight of consignments according to a volume-weight ratio of 1/3 (1 cubic metres = 333 kilograms for stackable freight, 1 LDM (loading meter) = 1750 kilograms for non-stackable freight).</p>
        <p>- The aforesaid rates are based on the prices for liquid fuel, tolls, ferry boat fares, etc. applicable at the time of the offer. Any noteworthy change/increase to these factors shall result in a corresponding amendment to the rates of our offer.</p>
        <p>- Our company operates as a forwarding agent in each transport. When no agreement to the contrary has been concluded between the parties in writing, the international road transport of freight that we undertake are governed by the provisions and terms of the ‘Geneva Convention’ on the international carriage of goods by road (C.M.R.) (Law 559/1977), the ‘General Terms for Freight Forwarding’ of the Association of International Freight Forwarders & Logistics Enterprises of Greece (IFFAG&L) and the terms of the Agreement concerning the International Carriage of Dangerous Goods by Road (ADR) (Law 1741/1987, as amended and in force).</p>
        <p>- Note regarding CMR compensation: Our company's liability for damage or losses to your goods being transported is set, pursuant to the CMR Convention, at approximately 8.33 SDR per kilogram of gross weight of damaged or lost freight with exemption for the amount of €300 and shall apply after a relevant written notice of damage within 48 hours of receipt by our company's employees. This notice must clearly and sufficiently set out the type and amount of damage, wear or loss. Furthermore, any compensation claim must also contain the relevant remarks in a delivery document (consignment note or CMR bill of lading). Where the value of the freight being transported exceeds €10.00 per kilogram, this must be declared to us in writing so that we secure separate insurance. CMR insurance shall under no circumstances substitute Transport or Storage Insurance for the freight. Otherwise, the compensation claim shall not be accepted by our company.</p>
        <p>- Any change/alteration in the quantitative or other basic details of the consignment(s) (change in location, dimensions, packaging, weight, etc.) from the corresponding details during loading shall result to in corresponding increases in the aforesaid rates and our final charges. Where these subsequent changes are significant and excessively hinder the seamless execution of the order, we reserve the right to refuse to load the freight.</p>
        <p>- The assignment of loading of a specific quantity and/or volume of merchandise and/or occupied truck space shall be binding on the Customer as the minimum charge by our company.</p>
        <p>- Merchandise shall be stacked in the means of transportation, unless otherwise required by our principal in writing, and shall be transported in accordance with the International/National regulations for the Safe Transportation of Goods and the instructions of the shipper of the goods or principal. If the stacking method indicated by the shipper is different from the one initially indicated by the customer and/or is not appropriate and in line with the regulations for the Safe Transportation of Goods, we are entitled to refuse the transportation. Where the transportation is carried out — under the responsibility of the shipper or our principal — we shall be entitled to amend (increase) our rates due to change in chargeable weight.</p>
        <p>- The forwarding services offered by our company are carried out in accordance with the details provided to us and we therefore bear no liability whatsoever if these details are incomplete, unclear and/or not factual.</p>
        <p>- The shipper of the goods and/or the principal are exclusively responsible for the provision and the correctness of all necessary transportation documents (commercial invoices, accuracy of the details declared for the filling of the CMR consignment note, packing lists, etc.). </p>
        <p>- Our company shall bear no liability whatsoever if its customers’ suppliers deliver their goods to our correspondents abroad without having made arrangements with us or informed us in advance.</p>
        <p>- Unless otherwise agreed upon in writing between the parties or unless there is such a special transportation clause, our invoices shall be paid in cash by the principal upon delivery of the consignment and/or our invoice. Offsetting rates in the event of damage or loss of goods shall not be acceptable or allowed.</p>
        <p>- In case of strike by the industries involved in the forwarding procedure in any manner or other events that constitute force majeure, we shall bear no liability whatsoever towards the customer or third parties for any delays or temporary inability to deliver the goods.</p>
        <p>- Acceptance of our offer by the customer shall automatically entail unreserved acceptance of all the foregoing terms of cooperation between the parties.</p><br /><br />
        <h5>Sincerely,</h5>
        <h5>GOLDAIR CARGO S.A.</h5><br /><br /><br />
        <p class="center-aligned small-text">Goldair Cargo S.A.<br />Head Office<br />Thesi Rykia, Gr 19300 Aspropyrgos Tel +302111804200, Fax +302111804249</p>
    </div>
  </div>
</template>

<script>
  import i18n from '@/i18n'
  import moment from 'moment'

  export default {
    name: 'OfferPrint',
    props: {
      title: {
        type: String,
        default: i18n.t('new-offer'),
      },
      printData: {
        type: Object,
        required: true,
      },
    },
    components: {
      moment,
    },
    computed: {
      offerModifiedDateForPrint (props) {
        return moment(props.printData.offerModifiedDate).format('DD/MM/YYYY')
      },
      selectedLanguage: () => {
        return i18n.locale
      },
    },
  }
</script>

<style lang="scss">
#pdf-content-div {
  .print-header-logo {
    text-align: center;
  }
  .print-header-content {
    display: block;
    width: 100%;
    clear: both;
    margin-top: 40px;
  }
  .header-content-title {
    display: block;
    color: $goldairBlue;
    margin: 30px 0px 40px 0px;
  }
  .color-black {
    color: black !important;
  }
  .center-aligned {
    text-align: center;
  }
  .small-text {
    font-size: 12px;
  }
  .print-terms {
    margin: 30px 0px 0px 0px;
    font-size: 14px;
  }
  .v-card__text{
    background-color: #fff;
  }
  .preview-card{
    background-color: #fff;
    &>.v-card__title {
      background-color: $goldairYellow;
      color: #fff;
      padding: 5px 20px;
      box-sizing: content-box;
      font-size: 18px;
      .link {
        cursor: pointer;
        font-size: 13px;
        &.printer-link{
          margin-right: 10px;
        }
      }
    }
  }
  .item-section {
    background-color: #fff;
    .item-title {
      .v-icon {
        display: none !important;
      }
    }
  }
  .v-card__actions {
    display: none;
  }

  .basic-info {
    background-color: #fff;
    display: flex;
    padding: 0 0 20px 0;

    .info-box{
      width: 20%;
      .label{
        display: block;
        color: $goldairBlue4;
        font-weight: bold;
        white-space: nowrap;
      }
      .value{
        display: block;
        color: $goldairBlue;
        font-weight: bold;
        line-height: 1.9em;
      }
    }
    .info-box.invisible {
      visibility: hidden !important;
    }
    .info-box.right-aligned {
      text-align: right;
    }
    .arrow-box {
      position: relative;
      width: 20%;
      text-align: center;

      .v-icon, .v-image {
        font-size: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .items-wrapper {
    background-color: #fff;
    margin-top: 10px;
    padding: 0;

    .offer-item {
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .item-card{
        border-radius: 0;
        box-shadow: none;
        .v-card__title{
          background-color: $goldairBlue4;
          border: 2px solid $goldairBlue;
          color: #fff;
          padding: 5px 10px;
          font-size: 13px;
        }
        .v-card__text{
          border: 2px solid $goldairBlue4;
          border-top: 0 !important;
          display: flex;
          .info-box{
            width: 20%;
            .label{
              display: block;
              color: $goldairBlue4;
              font-weight: bold;
              white-space: nowrap;
            }
            .value{
              display: block;
              color: $goldairBlue;
              font-weight: bold;
              line-height: 1.9em;
            }
          }
        }
      }
    }
  }

  .price-wrapper {
    background-color: #fff;
    padding: 20px 0 0 0;
    text-align: right;

    .label {
      color: $goldairBlue;
      font-size: 16px;
    }

    .price {
      color: $goldairYellow;
      font-size: 18px;
      font-weight: bold;
    }

    .vat {
      color: $goldairYellow;
      font-size: 13px;
    }
  }
}
</style>
