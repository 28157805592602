<template>
  <div
    id="lang-selector"
  >
    <span
      class="lang-value value-en"
      :class="{'active': $i18n.locale==='en'}"
      @click="setLocale('en')"
    >
      En
    </span>
    <span class="separator">|</span>
    <span
      class="lang-value value-gr"
      :class="{'active': $i18n.locale==='el'}"
      @click="setLocale('el')"
    >
      Gr
    </span>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'LanguageSelector',
    computed: {
      ...mapGetters('appGeneral', ['getLocale']),
    },
    created () {
      if (this.getLocale) {
        this._i18n.locale = this.getLocale
      }
    },
    methods: {
      setLocale: function (value) {
        this._i18n.locale = value
        this.$store.commit('appGeneral/SET_LOCALE', value)
      },
    },
  }
</script>

<style lang="scss" scoped>
  #lang-selector{
    color: #fff;
    min-width: 50px;
    .separator{
      margin: 0 3px;
    }
    .lang-value{
      font-size: 14px;
      cursor: pointer;
      &.active{
        color:#71abed;
      }
    }
  }
</style>
