<template>
  <v-app>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
  </v-app>
</template>

<script>
  import '@/styles/overrides.sass'
  import Vue from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import { authComputed } from '@/store/helpers'

  export default {
    name: 'App',
    metaInfo: {
      title: 'Goldair',
      titleTemplate: '%s | Ecommerce Platform',
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    computed: {
      ...authComputed,
    },
    created () {
      var self = this
      // this cronjob removes the expired notifications
      this.$crontab.addJob({
        name: 'flushNotifications',
        interval: {
          seconds: '/1',
        },
        job: function () {
          const allNotifications = self.getNotifications()
          const currentTimestamp = Date.now()
          if (allNotifications) {
            allNotifications.forEach((notification) => {
              if (currentTimestamp - notification.createdTime > Vue.prototype.$notificationsDuration) { // clear notifications every Vue.prototype.$notificationsDuration seconds
                self.removeNotification(notification.id)
              }
            })
          }
        },
      })

      if (this.loggedIn && Vue.prototype.$refreshDataFrequency > 0) {
        // this cronjob refreshes the data for offers/orders every n seconds
        this.$crontab.addJob({
          name: 'refreshData',
          interval: {
            seconds: '/' + Vue.prototype.$refreshDataFrequency,
          },
          job: function () {
            self.$store.dispatch('orders/action_updateOrdersInVuex')
            self.$store.dispatch('orders/action_updateOffersInVuex')
          },
        })
      }
    },
    methods: {
      ...mapGetters('notificationAlerts', ['getNotifications']),
      ...mapActions('notificationAlerts', ['removeNotification']),
    },
  }
</script>

<style lang="scss">
@import './assets/styles/global.scss';
/***** TRANSITIONS *****/
.fade-enter {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}

.fade-leave-to {
  opacity: 0;
}
</style>
