<template>
  <div class="dn-tooltip">
    <slot />
  </div>
</template>

<script>
  export default {
    name: 'DnTooltip',
    props: {
      color: {
        type: String,
        default: 'primary',
      },
    },
  }
</script>

<style lang="scss" scoped>
  .dn-tooltip{
    margin: 10px auto;
    color: #fff;
    background-color: $goldairBlue;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    &:after{
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      bottom: -5px;
      left: 50%;
      background-color: #285284;
      transform: rotate(
        45deg
    );
  }
  }
</style>
