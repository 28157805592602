<template>
  <div
    id="disable-screen-modal"
  >
    <v-progress-circular
      indeterminate
      color="primary"
    />
  </div>
</template>

<script>
  export default {
    name: 'ScreenDeactivator',
  }
</script>

<style lang="scss" scoped>
  #disable-screen-modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $bgWhite06;
    overflow: hidden;
    z-index: 99999;
    .v-progress-circular{
      height: 64px;
      width: 64px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
</style>
