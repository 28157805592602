<template>
  <v-card
    width="400"
    class="mx-auto mt-5"
  >
    <v-card-text>
      <v-form
        ref="registerForm"
        v-model="isFormValid"
      >
        <v-text-field
          v-model="customer.email"
          :label="$t('email')"
          :rules="emailRules"
          required
        />
        <v-text-field
          v-model="customer.name"
          :label="$t('full-name')"
          :rules="nameRules"
          required
        />
        <v-text-field
          v-model="customer.password"
          :type="showPassword ? 'text' : 'password'"
          :label="$t('password')"
          :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          required
          :rules="passwordRules"
          @click:append="showPassword = !showPassword"
        />
        <v-text-field
          v-model="customer.passwordVerification"
          :type="showPasswordVerification ? 'text' : 'password'"
          :label="$t('verify-password')"
          :append-icon="showPasswordVerification ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          required
          :rules="[(customer.password === customer.passwordVerification) || 'Passwords must match']"
          @click:append="showPasswordVerification = !showPasswordVerification"
        />
      </v-form>
      <loader v-show="loader" />
    </v-card-text>
    <v-card-actions>
      <div class="actions-inner-wrapper">
        <v-btn
          id="registerBtn"
          color="primary"
          class="form-btn"
          :disabled="!isFormValid"
          style="margin: 0 auto;"
          @click="submitForm()"
        >
          {{ $t('register') }}
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import BasicForm from '@/mixins/BasicForm'
  import Loader from '@/components/Forms/Elements/Loader'
  import { requiredRule, emailRule, passwordRegexRule } from '@/store/formValidationRules'

  export default {
    name: 'CustomerRegisterForm',
    components: {
      Loader,
    },
    mixins: [
      BasicForm,
    ],
    props: {
      callbacks: Object,
    },
    data: () => ({
      isFormValid: false,
      customer: {
        email: '',
        name: '',
        surname: '',
        password: '',
        passwordVerification: '',
      },
      showPassword: false,
      showPasswordVerification: false,
      emailRules: [
        requiredRule('Email is required'),
        emailRule(),
      ],
      nameRules: [
        requiredRule('Name is required.'),
      ],
      passwordRules: [
        passwordRegexRule(),
      ],
    }),
    methods: {
      ...mapActions('auth', ['register']),
      ...mapGetters('login', ['getOfferToSave']),
      async submit (token) {
        const { email, name, password } = this.customer
        const self = this
        const offerToSave = this.getOfferToSave()
        if (offerToSave) {
          offerToSave.email = email
          this.$store.commit('login/SET_OFFER_TO_SAVE', offerToSave)
        }
        this.$store.dispatch('auth/register', {
          Email: email,
          Name: name,
          Password: password,
          ReCaptchaResponse: token,
        })
          .then(() => {
            self.$store.dispatch('notificationAlerts/addNotification', {
              text: 'Account created successfully. Please, check your email to verify your account.',
              type: 'success',
            },
            )
            self.$refs.registerForm.reset()
            self.tab = 0
          })
          .finally(() => {
            self.loader = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>

#registerBtn{
  text-transform: capitalize;
}

.v-card__actions{
  padding: 0 !important;
  .actions-inner-wrapper{
    width: 100%;
    text-align: center;
    margin-top: 30px;
    .forgot-password-button{
      display: inline-block;
      margin: 0 auto;
      text-decoration: underline;
      font-size: 11px;
    }
    .v-card__text{
      padding: 0 !important;
    }
  }
}

.v-card{
  margin-top: 0 !important;
  padding: 30px;
  box-shadow: none !important;
  .v-card__text{
    padding: 0 !important;
  }
}
</style>
