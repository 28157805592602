<template>
  <v-row
    class="datepicker"
    :class="{'force-no-opaque': forceNoOpaque}"
  >
    <v-col>
      <v-menu
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="date"
            :label="label"
            append-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            :disabled="readOnly"
            :rules="rules"
            v-on="on"
          />
        </template>
        <v-date-picker
          v-if="!readOnly"
          v-model="date"
          @change="$emit('change', $event);"
          @input="menu2 = false"
        />
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'DatePicker',
    props: {
      label: {
        type: String,
        default: '',
      },
      initValue: {
        type: [Number, String],
        default: '',
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      rules: {
        type: Array,
        default: () => [],
      },
      forceNoOpaque: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      date: null,
      menu: false,
      modal: false,
      menu2: false,
    }),
    created () {
      if (this.initValue) {
        this.date = this.initValue
      }
    },
  }
</script>

<style lang="scss" >
.datepicker{
  padding: 12px !important;
  &:first-child{
    padding-left: 0 !important;
  }
  &:last-child{
    padding-right: 0 !important;
  }
  .col:first-child{
    padding: 0 !important;
  }
  &.force-no-opaque {
    .v-input{
      opacity: 1 !important;
    }
  }
}
</style>
