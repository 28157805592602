import { make } from 'vuex-pathify'
const defaultItems = [
  // {
  //   titleId: 'Regular Tables',
  //   icon: 'mdi-clipboard-outline',
  //   to: '/tables/regular/',
  //   permissions: LOGGED_IN,
  // },
  // {
  //   titleId: 'Typography',
  //   icon: 'mdi-format-font',
  //   to: '/components/typography/',
  //   permissions: LOGGED_IN,
  // },
  // {
  //   titleId: 'Icons',
  //   icon: 'mdi-chart-bubble',
  //   to: '/components/icons/',
  //   permissions: LOGGED_IN,
  // },
  // {
  //   titleId: 'Google Maps',
  //   icon: 'mdi-map-marker',
  //   to: '/maps/google/',
  //   permissions: LOGGED_IN,
  // },
  // {
  //   titleId: 'Notifications',
  //   icon: 'mdi-bell',
  //   to: '/components/notifications/',
  //   permissions: LOGGED_IN,
  // },
  {
    titleId: 'menu.create-offer',
    icon: 'mdi-package-variant',
    to: '/',
  },
]
const loggedInItems = [
  {
    titleId: 'menu.my-orders',
    icon: 'mdi-playlist-check',
    to: '/my-orders',
  },
  {
    titleId: 'menu.my-offers',
    icon: 'mdi-playlist-edit',
    to: '/my-offers',
  },
  {
    titleId: 'menu.my-account',
    icon: 'mdi-account-circle',
    to: '/account',
  },
  {
    titleId: 'menu.logout',
    icon: 'mdi-logout',
    onClick: 'logout',
  },
]
const guestItems = [
  {
    titleId: 'menu.login',
    icon: 'mdi-login',
    onClick: 'showLogin',
  },
]

// Data
const state = {
  drawer: null,
  mini: false,
  items: [
    ...defaultItems,
    ...guestItems,
  ],
}

const mutations = make.mutations(state)

const actions = {
  ...make.actions(state),
  init: async ({ dispatch }) => {
    //
  },
}

const getters = {
  calculateMenuItems: (state) => (data) => {
    if (data === 'loggedIn') {
      return defaultItems.concat(loggedInItems)
    } else {
      return defaultItems.concat(guestItems)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
