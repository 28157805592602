<template>
  <v-card
    class="preview-card"
    v-bind="$attrs"
  >
    <v-card-title>
      <strong>{{ getTitle }}</strong>
      <v-spacer />
      <span
        v-ripple
        class="action-link link printer-link"
        @click="printOrder()"
      >
        <v-icon
          color="white"
        >mdi-printer</v-icon> Print
      </span>
    </v-card-title>
    <v-card-text class="price-wrapper">
      <v-container>
        <v-row>
          <v-col class="left-col">
            <p>
              <span class="label">Status:</span> <span
                class="value status-after"
                :class="getStatusString(order.Status).toLowerCase().replace(/\s/g , '_')"
              >{{ getStatusString(order.Status) }}</span>
            </p>
            <p>
              <span class="label">Payment Type:</span> <span
                class="value"
              >{{ getPaymentString(order.PaymentType) }}</span>
            </p>
            <p v-if="order.PaymentType === 'ByCard'">
              <span
                class="label"
              >Payment Status:</span>
              <span
                v-if="order.Payments.length && order.Payments[0].Paid"
                class="value payment-status status-paid"
              > Paid</span>
              <span
                v-else
                class="value payment-status status-unpaid"
              > Unpaid</span>
            </p>
          </v-col>
          <v-col class="right-col">
            <div class="summary">
              <!-- <p class="total"> -->
                <!-- <span class="label total-cost">Total Cost:</span> <span class="value price">&euro;{{ order.Total }} </span> <span class="vat">+VAT(24%)</span> -->
                <!-- <span class="label total-cost">Total Cost:</span> <span class="value price">&euro;{{ (Math.round((order.Total + Number.EPSILON) * 100) / 100).toFixed(2) }} </span> <span class="vat">+VAT(24%)</span> -->
              <!-- </p> -->
              <p class="total">
                <span class="label">{{ $t('cost') }}: </span>
                <span class="price">{{ (Math.round((order.Total + Number.EPSILON) * 100) / 100).toFixed(2) }}&euro; </span>
              </p>
              <p class="total">
                <!-- <span class="label">{{ $t('vat-empty') }}({{ Math.round(((Math.round((order.Vat + Number.EPSILON) * 100) / 100).toFixed(2) * 100) / (Math.round((order.TotalWithVat + Number.EPSILON) * 100) / 100).toFixed(2)) }}%): </span> -->
                <span class="label">{{ $t('vat-empty') }}({{ Math.round((Math.round((order.Vat + Number.EPSILON) * 100) / 100)/(Math.round((order.Total + Number.EPSILON) * 100) / 100)*100) }}%): </span>
                <span class="price">{{ (Math.round((order.Vat + Number.EPSILON) * 100) / 100).toFixed(2) }}&euro; </span>
              </p>
              <p class="total">
                <span class="label">{{ $t('total-cost') }}: </span>
                <span class="price">{{ (Math.round((order.TotalWithVat + Number.EPSILON) * 100) / 100).toFixed(2) }}&euro; </span>
              </p>
              <p
                v-if="order.ShippingTrackingCode"
                class="track-order"
              >
                <span class="label">Tracking Number:</span>
                <span class="value"> {{ order.ShippingTrackingCode }}</span>
                <br>
                <span class="label track-order-text">Track Order <a
                  :href="trackingUrl"
                  target="_blank"
                >Here</a></span>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-text>
      <div class="basic-info">
        <div class="info-box">
          <span class="label">Country From:</span>
          <span class="value">{{ (order.AddressFrom.Country) ? order.AddressFrom.Country.DisplayName : '' }}</span>
        </div>
        <div class="info-box">
          <span class="label">Postcode From:</span>
          <span class="value">{{ order.AddressFrom.PostCode }}</span>
        </div>
        <div class="arrow-box">
          <v-img
            src="@/assets/icons/arrow.png"
            width="25%"
          />
        </div>
        <div class="info-box">
          <span class="label">Country To:</span>
          <span class="value">{{ (order.AddressTo.Country) ? order.AddressTo.Country.DisplayName : '' }}</span>
        </div>
        <div class="info-box">
          <span class="label">Postcode To:</span>
          <span class="value">{{ order.AddressTo.PostCode }}</span>
        </div>
      </div>
      <div class="items-wrapper">
        <div
          v-for="(item, index) in order.Items"
          :key="index"
          class="order-item"
        >
          <v-card
            class="item-card"
          >
            <v-card-title>{{ getItemTitle(index) }}</v-card-title>
            <v-card-text>
              <div class="info-box">
                <span class="label">Packaging Type:</span>
                <span class="value">{{ item.PackagingType.Name+' ('+item.Quantity+')' }}</span>
              </div>
              <div class="info-box">
                <span class="label">Width (cm):</span>
                <span class="value">{{ item.Width }}</span>
              </div>
              <div class="info-box">
                <span class="label">Height (cm):</span>
                <span class="value">{{ item.Height }}</span>
              </div>
              <div class="info-box">
                <span class="label">Length (cm):</span>
                <span class="value">{{ item.Length }}</span>
              </div>
              <div class="info-box">
                <span class="label">Weight (kg):</span>
                <span class="value">{{ item.Weight }}</span>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import { authComputed, dataMappers } from '@/store/helpers'

  export default {
    name: 'OrderPreviewBasic',
    inheritAttrs: true,
    props: {
      oid: {
        type: [Number, String],
        required: true,
      },
      callbacks: {
        type: Object,
        default: () => { return {} },
      },
    },
    data () {
      return {
        order: {},
      }
    },
    computed: {
      ...authComputed,
      ...mapGetters('apiGeneric', ['getCountryByCode']),
      ...mapGetters('orders', ['getOrderByOid']),
      getTitle: function () {
        return 'Order #' + this.order.Oid
      },
      trackingUrl: () => Vue.prototype.$orderTrackingUrl,
    },
    created () {
      const orderObj = this.getOrderByOid(this.oid)
      const redirectPage = 'Account/MyOrders'
      if (!orderObj) {
        this.$store.dispatch('notificationAlerts/addNotification', {
          text: 'Offer with id ' + this.oid + ' does not exist',
          type: 'error',
        },
        )
        this.$router.push({ name: redirectPage })
      } else {
        this.order = orderObj
      }
    },
    methods: {
      ...mapGetters('apiGeneric', ['getCountries', 'getPackagingTypes']),
      ...mapGetters('customer', ['getCustomerEmail', 'getCustomerName']),
      getCountriesOptions: function () {
        const countriesObj = this.getCountries()
        const selectOptions = []
        for (let i = 0; i < countriesObj.length; i++) {
          selectOptions.push({
            label: countriesObj[i].DisplayName,
            value: countriesObj[i].Oid,
          })
        }
        return selectOptions
      },
      getCountryIdByCode: function (code) {
        const countryObj = this.getCountryByCode(code)
        if (countryObj) {
          return countryObj.Oid
        }
        return null
      },
      printOrder: function () {
        if (this.callbacks.print) {
          this.callbacks.print()
        } else {
          window.print()
        }
      },
      getItemTitle: function (index) {
        const num = (index + 1)
        return 'Item #' + num.toString().padStart(2, '0')
      },
      getStatusString: (code) => {
        return dataMappers.orderStatusCodeToStringMapper(code)
      },
      getPaymentString: (paymentStr) => {
        var paymentLabel = ''
        switch (paymentStr) {
          case 'ByCard':
            paymentLabel = 'Credit Card'
            break
          case 'ByBankDeposit':
            paymentLabel = 'Bank Deposit'
            break
          default:
            paymentLabel = ''
        }
        return paymentLabel
      },
    },
  }
</script>

<style lang="scss" scoped>
  .preview-card {
    text-align: center;
    border-radius: 0;
    &>.v-card__title{
      background-color: $goldairYellow;
      color: #fff;
      padding: 5px 20px;
      box-sizing: content-box;
      font-size: 18px;
      .link {
        cursor: pointer;
        font-size: 13px;
        &.printer-link{
          margin-right: 10px;
        }
      }
    }
    .v-card__text {
      padding: 25px;
      .basic-info {
        display: flex;
        padding: 0 0 20px 0;
        .info-box{
          width: 20%;
          .value{
            line-height: 1.9em;
          }
        }
        .arrow-box{
          position: relative;
          width: 20%;
          text-align: center;
          .v-icon, .v-image{
            font-size: 50px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
      .items-wrapper {
        margin-top: 10px;
        .order-item{
          &:not(:last-child){
            margin-bottom: 20px;
          }
          .item-card{
            border-radius: 0;
            box-shadow: none;
            .v-card__title{
              background-color: $goldairLightBlue2;
              border: none;
              color: #fff;
              font-size: 14px;
              padding: 5px 10px;
            }
            .v-card__text{
              border: 1px solid $goldairBlue4;
              border-top: 0 !important;
              display: flex;
              padding: 13px 20px;
              .info-box{
                width: 20%;
                .value{
                  line-height: 1.9em;
                }
              }
            }
          }
        }
      }
      .info-box{
        .label{
          display: block;
          color: $goldairBlue4;
          font-weight: 400;
          white-space: nowrap;
          font-size: 13px;
        }
        .value{
          display: block;
          color: $goldairBlue;
          font-weight: bold;
          font-size: 18px;
          .value{
            line-height: 1.9em;
          }
        }
      }
    }
    .price-wrapper{
      background-color: #faf7ef;
      color: $goldairBlue;
      border-bottom: 1px solid $goldairLightBlue2;
      padding-bottom: 10px !important;
      .label{
        color: #5b8fbd;
        font-size: 15px;
        font-weight: 400;
      }
      .left-col{
        text-align: left;
      }
      .right-col{
        .summary{
          text-align: right;
          .value{
            font-weight: bold;
            font-size: 18px;
          }
          .price{
            color: $goldairYellow;
            font-size: 18px;
          }
          .total:nth-child(3) > .price{
            font-weight: bold;
          }
          .vat{
            color: $goldairYellow;
            font-size: 13px;
          }
          .track-order{
            .track-order-text{
              color: $goldairBlue;
              font-size: 13px;
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 959px) {
    #app {
      #main-wrapper {
        .preview-card{
          width: 100%;
        }
        .price-wrapper {
          .row{
            display: block;
            .left-col {
              text-align: left;
              width: 100%;
              display: block;
              padding-bottom: 0;
            }
            .right-col {
              padding-top: 0;
              .summary {
                text-align: left;
                width: 100%;
                display: block;
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }

  @media all and (max-width: 750px) {
    #app {
      #main-wrapper {
        .preview-card{
          .price-wrapper {
            padding: 10px !important;
            .row{
              display: block;
              .left-col {
                text-align: left;
                width: 100%;
                display: block;
              }
              .right-col {
                .summary {
                  text-align: left;
                  width: 100%;
                  display: block;
                }
              }
            }
          }
          >.v-card__text{
            padding: 10px;
          }
          .basic-info {
            display: block;
            padding-bottom: 0;
            .info-box {
              display: inline-block;
              width: 50%;
              margin: 0 0 20px 0px;
            }
            .arrow-box{
              display: none !important;
            }
          }

          .items-wrapper{
            .order-item{
              .item-card{
                .v-card__text{
                  padding: 10px;
                  display: block;
                  .info-box{
                    &:first-child{
                      display: flex;
                      width: 100%;
                      margin-bottom: 10px;
                      .value{
                        margin-left: 10px;
                      }
                    }
                    display: inline-block;
                    width: 50% !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
</style>
