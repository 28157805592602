import { apiClient } from '../../services/axiosService'
import Vue from 'vue'

const PAYMENT_API_VERSION = 57

export function loadCheckoutScript () {
  var ibankScript = document.getElementById('checkout-script')
  if (!ibankScript) {
    ibankScript = document.createElement('script')
    ibankScript.id = 'checkout-script'
  }
  ibankScript.setAttribute('src', 'https://ibanke-commerce.nbg.gr/checkout/version/' + PAYMENT_API_VERSION + '/checkout.js')
  ibankScript.setAttribute('data-error', Vue.prototype.errorUrl)
  ibankScript.setAttribute('data-cancel', Vue.prototype.cancelUrl)
  ibankScript.setAttribute('data-timeout', Vue.prototype.timeoutUrl)
  document.head.appendChild(ibankScript)
  return ibankScript
}

function execPaymentCall (configureObj) {
  window.Checkout.configure(configureObj)
  window.Checkout.showPaymentPage() // for popup change to window.Checkout.showLightbox()
}

const actions = {
  action_payOrderWithCard ({ commit, rootGetters }, data) {
    const refCode = rootGetters['orders/getOrderPaymentRefCode'](data.orderID)
    if (refCode) {
      apiClient.get(`${Vue.prototype.$backendApiUrl}/PaymentSession`, { params: { paymentRefCode: refCode } })
        .then(function (response) {
          if (response.status === 200) {
            const responseData = response.data
            const configureObj = {
              version: PAYMENT_API_VERSION,
              merchant: responseData.MerchantId,
              lineOfBusiness: 'ENFORCE',
              order: {
                amount: function () {
                  // Dynamic calculation of amount
                  return responseData.amount
                },
                currency: 'EUR',
                description: 'Goldair Cargo Order #' + data.orderID,
                id: data.orderID,
                reference: responseData.PaymentRefCode,
                // certainty: 'ESTIMATED', // gia doseis
              },
              session: {
                id: responseData.SessionId,
              },
              interaction: {
                displayControl:
                  {
                    billingAddress: 'HIDE',
                  },
                operation: 'PURCHASE', // set this field to 'PURCHASE' for Hosted Checkout to perform a Pay Operation.
                // operation: 'AUTHORIZE', // gia doseis set this field to 'PURCHASE' for Hosted Checkout to perform a Pay Operation.
                merchant: {
                  name: 'GOLDAIR CARGO S.A.',
                },
              },
            }
            commit('SET_PENDING_PAYMENT_REFCODE', refCode)
            if (!window.Checkout) { // load script only if not already loaded
              const checkoutScript = loadCheckoutScript()
              checkoutScript.onload = function () {
                execPaymentCall(configureObj)
              }
            } else {
              execPaymentCall(configureObj)
            }
          }
      })
    }
  },
  action_getPaymentResult ({ commit, rootGetters }, data) {
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/PaymentResult`, { params: { paymentRefCode: data.paymentRefCode, resultIndicator: data.resultIndicator } })
  },
}

export default {
  namespaced: true,
  actions,
}
