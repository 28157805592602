<template>
  <v-row
    class="timepicker"
    :class="{'force-no-opaque': forceNoOpaque}"
  >
    <v-col>
      <label>{{ label }}</label>
      <vue-timepicker
        v-model="time"
        :disabled="readOnly"
        format="HH:mm"
        @change="$emit('change', $event);"
        required
      />
    </v-col>
  </v-row>
</template>

<script>
  import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
  export default {
    name: 'TimePicker',
    components: {
      VueTimepicker,
    },
    props: {
      label: {
        type: String,
        default: '',
      },
      initValue: {
        type: String,
        default: '',
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      rules: {
        type: Array,
        default: () => [],
      },
      forceNoOpaque: {
        type: Boolean,
        default: false,
      },
    },
    data: function () {
      return {
        time: {
          HH: '',
          mm: '',
        },
      }
    },
    created () {
      if (this.initValue) {
        this.time = this.initValue
      }
    },
  }
</script>

<style lang="scss" scoped>
@import '~vue2-timepicker/dist/VueTimepicker.css';
.timepicker{
  padding: 12px !important;
  &:first-child{
    padding-left: 0 !important;
  }
  &:last-child{
    padding-right: 0 !important;
  }
  .col:first-child{
    padding: 0 !important;
  }
  &.force-no-opaque {
    .v-input{
      opacity: 1 !important;
    }
  }
}
</style>
