import { apiClient } from '@/services/axiosService'
import Vue from 'vue'

const state = {
  user: null,
  error: null,
}

const getters = {
  loggedIn (state) {
    return !!state.user
  },
  getUserData (state) {
    return state.user
  },
}

const mutations = {
  SET_USER_DATA (state, userData) {
    state.user = userData
    localStorage.setItem('user', JSON.stringify(userData))
    apiClient.defaults.headers.common.Authorization = `Bearer ${userData.Token}`
  },
  CLEAR_USER_DATA (state) {
    localStorage.removeItem('user')
    state.user = null
    delete state.user
  },
}

const actions = {
  register ({ commit }, credentials) {
    return apiClient.post(`${Vue.prototype.$backendApiUrl}/Customers`, credentials)
  },
  login ({ commit, rootGetters }, credentials) {
    return apiClient.post(`${Vue.prototype.$backendApiUrl}/Login`, credentials)
      .then(
        ({ data }) => {
          const menuItems = rootGetters['app/calculateMenuItems']('loggedIn')
          commit('app/items', menuItems, { root: true })
          commit('SET_USER_DATA', data)
        },
      )
  },
  logout ({ commit, rootGetters }) {
    const menuItems = rootGetters['app/calculateMenuItems']('guest')
    const offerToSaveData = rootGetters['login/getOfferToSave']
    const email = rootGetters['customer/getCustomerEmail']

    commit('app/items', menuItems, { root: true })
    if (offerToSaveData) {
      if (!offerToSaveData.email || offerToSaveData.email === email) {
        commit('login/CLEAR_OFFER_TO_SAVE', null, { root: true })
      }
    }
    commit('appGeneral/SET_INFO_COMPONENT_MANUALLY_CLOSED', false,
    { root: true },
    )
    commit('orders/CLEAR_ORDERS', null,
    { root: true },
    )
    commit('orders/CLEAR_OFFERS', null,
    { root: true },
    )
    commit('customer/CLEAR_CUSTOMER_ADDRESSES', null,
      { root: true },
    )
    commit('customer/CLEAR_CUSTOMER_DATA', null,
      { root: true },
    )
    commit('CLEAR_USER_DATA')
    window.location.reload()
  },
  verify ({ commit }, code) {
    return apiClient.post(`${Vue.prototype.$backendApiUrl}/CustomerVerification`, {
      Code: code,
    })
  },
  async resetPassword ({ commit }, payload) {
    return apiClient.post(`${Vue.prototype.$backendApiUrl}/ResetPassword`, payload)
  },
  resetPasswordRequest ({ commit }, email) {
    return apiClient.post(`${Vue.prototype.$backendApiUrl}/ResetPasswordRequest`, {
      Email: email,
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
