// Imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import { apiClient } from '../services/axiosService'

import {
  layout,
  route,
} from '@/util/routes'
import NProgress from 'nprogress'
// import i18n from '../i18n'

Vue.use(Router)

const routeData = [
  layout('Default', [
    // Pages
    { ...route('UserProfile', null, 'components/profile'), ...{ meta: { requiresAuth: true } } },

    // Components
    { ...route('Notifications', null, 'components/notifications'), ...{ meta: { requiresAuth: true } } },
    { ...route('Icons', null, 'components/icons'), ...{ meta: { requiresAuth: true } } },
    { ...route('Typography', null, 'components/typography'), ...{ meta: { requiresAuth: true } } },

    // Tables
    { ...route('Regular Tables', null, 'tables/regular'), ...{ meta: { requiresAuth: true } } },

    // Maps
    { ...route('Google Maps', null, 'maps/google'), ...{ meta: { requiresAuth: true } } },

    {
      path: '/',
      name: 'Home',
      component: () => import('../views/Offer/Create.vue'),
      meta: { requiresAuth: false },
      props: true,
      beforeEnter (routeTo, routeFrom, next) {
        // ean einai loggedIn , 8a exoun hdh travhxtei ta data stp login
        const loggedIn = store.getters['auth/loggedIn']
        if (!loggedIn) {
          store.dispatch('apiGeneric/action_getCountries')
            .then(() => {
              store.dispatch('apiGeneric/action_getPackagingTypes')
                .catch(err => {
                  this.$store.dispatch('notificationAlerts/addNotification', {
                      text: err.response.data.ErrorCode + ': ' + err.response.data.ErrorDescription,
                      type: 'error',
                    },
                  )
                })
            })
            .catch(err => {
              store.dispatch('notificationAlerts/addNotification', {
                  text: err.response.data.ErrorCode + ': ' + err.response.data.ErrorDescription,
                  type: 'error',
                },
              )
            })
            .finally(() => {
              next()
            })
        } else {
          next()
        }
      },
    },
    {
      path: '/offer/edit/:oid',
      name: 'Offer/Edit',
      component: () => import('../views/Offer/Edit.vue'),
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: '/offer/view/:oid',
      name: 'Offer/View',
      component: () => import('../views/Offer/View.vue'),
      meta: { requiresAuth: true },
      props: true,
      beforeEnter (routeTo, routeFrom, next) {
        if (Vue.prototype.$refreshDataFrequency <= 0 || !store.getters['orders/getOfferByOid'](routeTo.params.oid)) {
          // add token to apiClient because the right configuration has not be loaded yet
          const userData = store.getters['auth/getUserData']
          const token = userData.Token
          apiClient.defaults.headers.Authorization = `Bearer ${token}`
          store.dispatch('orders/action_getOfferById', {
            offerId: routeTo.params.oid,
          })
          .then(() => {
              next()
          })
          .catch((response) => {
            // logout mono ean exei lh3ei to token
            if (response.ErrorCode === 401) {
              store.dispatch('auth/logout')
            } else {
              next({ name: 'NotFound' })
            }
          })
          return
        }
        next()
      },
    },
    { ...route('About', null, '/about'), ...{ meta: { requiresAuth: true } } },
    {
      ...route('Account/View', null, '/account'),
      ...{
        meta: { requiresAuth: true },
      },
    },
    {
      ...route('Account/MyOrders', null, '/my-orders'),
      ...{
        meta: { requiresAuth: true },
      },
    },
    {
      ...route('Account/MyOffers', null, '/my-offers'),
      ...{
        meta: { requiresAuth: true },
      },
    },
    // den xrhsimopoiw to function route giati den douleuoun swsta ta url params
    {
      path: '/order/view/:oid',
      name: 'Order/View',
      component: () => import('../views/Order/View.vue'),
      meta: { requiresAuth: true },
      props: true,
      beforeEnter (routeTo, routeFrom, next) {
        // load from server only if Vue.prototype.$refreshDataFrequency === 0 or it does not exists in vuex
        if (Vue.prototype.$refreshDataFrequency <= 0 || !store.getters['orders/getOrderByOid'](routeTo.params.oid)) {
          // add token to apiClient because the right configuration has not be loaded yet
          const userData = store.getters['auth/getUserData']
          const token = userData.Token
          apiClient.defaults.headers.Authorization = `Bearer ${token}`
          store.dispatch('orders/action_getOrderById', {
            orderId: routeTo.params.oid,
          }).then(() => {
            next()
          })
            .catch((response) => {
              // logout mono ean exei lh3ei to token
              if (response.ErrorCode === 401) {
                store.dispatch('auth/logout')
              } else {
                next({ name: 'NotFound' })
              }
            })
          return
        }
        next()
      },
    },
    {
      path: '/order-create/:oid',
      name: 'Order/Create',
      component: () => import('../views/Order/Create.vue'),
      meta: { requiresAuth: true },
      props: true,
      beforeEnter (routeTo, routeFrom, next) {
        // load from server only if Vue.prototype.$refreshDataFrequency === 0 or it does not exists in vuex
          // add token to apiClient because the right configuration has not be loaded yet
          const userData = store.getters['auth/getUserData']
          const token = userData.Token
          apiClient.defaults.headers.Authorization = `Bearer ${token}`
          store.dispatch('customer/action_getCustomerAddresses')
            .then(() => {
            next()
          })
            .catch((response) => {
              // logout mono ean exei lh3ei to token
              if (response.ErrorCode === 401) {
                store.dispatch('auth/logout')
              } else {
                next({ name: 'NotFound' })
              }
            })
      },
    },
    {
      path: '/success/:oid',
      name: 'Order/Success',
      component: () => import('../views/Order/Result/Success.vue'),
      meta: { requiresAuth: true },
      props: true,
      beforeEnter (routeTo, routeFrom, next) {
        if (store.getters['orders/successPageAllowedForOid'](routeTo.params.oid)) {
          next()
          return
        }
        next({ name: 'Home' })
      },
    },
    {
      path: '/failure',
      name: 'Failure',
      component: () => import('../views/Order/Result/Failure.vue'),
      meta: { requiresAuth: true },
      props: true,
      beforeEnter (routeTo, routeFrom, next) {
        if (store.getters['orders/errorPageAllowed']()) {
          next()
          return
        }
        next({ name: 'Home' })
      },
    },
    route('TermsAndConditions', null, '/terms-and-conditions'),
    route('NotFound', null, '/404'),
    {
      ...route('Verify', null, '/verify'),
      ...{
        beforeEnter (routeTo, routeFrom, next) {
          store.dispatch('auth/verify', routeTo.query.code)
            .then((data) => {
                store.dispatch('notificationAlerts/addNotification', {
                  text: 'Your account has been successfully verified. You can login with your credentials',
                  type: 'success',
              })
            })
            .catch(err => {
              store.dispatch('notificationAlerts/addNotification', {
                  text: err.response.data.ErrorCode + ': ' + err.response.data.ErrorDescription,
                  type: 'error',
                },
              )
            })
            .finally(() => {
              next({ name: 'Home' })
            })
        },
      },
    },
    {
      ...route('Password/ResetPassword', null, '/reset-password'),
      ...{
        beforeEnter (routeTo, routeFrom, next) {
          const payload = {
            Test: true,
            NewPassword: 'test', // real password is not needed here. just check if the code is valid
            Code: routeTo.query.code,
          }
          store.dispatch('auth/resetPassword', payload)
            .then(() => {
              next()
            })
            .catch(() => {
              // key is invalid so redirect to home
              next({
                name: 'NotFound',
              })
            })
        },
      },
      meta: { guestOnly: true },
    },
    {
      ...route('Payment/Success', null, '/payment-success'),
      meta: { requiresAuth: true },
    },
    {
      ...route('Payment/Failure', null, '/payment-failure'),
      meta: { requiresAuth: true },
    },
    {
      path: '*',
      redirect: { name: 'NotFound' },
    },
  ]),
]
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: routeData,
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  // return to.path.endsWith('/') ? next() : next(trailingSlash(to.path))
  const loggedIn = store.getters['auth/loggedIn']
  if (to.matched.some(record => record.meta.guestOnly) && loggedIn) {
    next({ name: 'Home' })
  } else {
    // an einai loggedin den ton afhnw na paei pali sth login kai ton stelnw sthn prohgoumenh selida tou h' sth home
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!loggedIn) {
        next({
          name: 'Home',
          params: { nextUrl: to.fullPath },
        })
      } else {
        // edw einai loggedin opote ton afhnoume
        next()
      }
    }
    next()
  }
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
