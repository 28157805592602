const state = {
  notifications: [],
  counter: 0,
}

const getters = {
  getNotifications (state) {
    // const notifications = state.notifications
    // state.notifications = []
    // return notifications
    return state.notifications
  },
  hasNotifications (state) {
    return state.notifications.length > 0
  },
}

const mutations = {
  ADD_NOTIFICATION (state, notification) {
    state.notifications.push(notification)
  },
  REMOVE_NOTIFICATION (state, notificationId) {
    for (var i = 0; i < state.notifications.length; i++) {
      if (state.notifications[i].id === notificationId) {
        state.notifications.splice(i, 1)
      }
    }
  },
  INCREMENT_COUNTER () {
    state.counter++
  },
}

const actions = {
  addNotification ({ commit, state }, notificationData) {
    // set default values for notification
    commit('INCREMENT_COUNTER')
    const notification = {
      text: '',
      type: 'info',
      dismissible: true,
    }

    const mergedData = { ...notification, ...notificationData }
    state.counter++
    mergedData.id = state.counter
    mergedData.createdTime = Date.now()
    return commit('ADD_NOTIFICATION', mergedData)
  },
  removeNotification ({ commit }, notificationId) {
    return commit('REMOVE_NOTIFICATION', notificationId)
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
