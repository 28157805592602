<template>
  <div>
    <v-dialog
      v-model="loginDialog"
      :persistent="!loggedIn && routeIsPrivate"
      @click:outside="closeCallback()"
    >
      <div class="modal-inner-wrapper">
        <v-img
          :src="require('@/assets/icons/x_close.png')"
          class="close-modal-button"
          style="cursor: pointer"
          @click="closeCallback()"
        />
        <v-img
          class="goldair-logo"
          :src="require('@/assets/images/goldair_cargo_blue.png')"
        />
        <span class="intro-message">Make Your Free Goldair Freight<br>Quote Now!</span>
        <notifications-area />
        <v-tabs
          v-model="tab"
          align-with-title
          hide-slider
        >
          <v-tab
            v-for="tab in Tabs"
            :key="tab"
          >
            {{ $t(tab) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="tab in Tabs"
            :key="tab"
          >
            <v-card flat>
              <login-form
                v-if="tab === 'Login'"
                class="loginForm"
              />
              <register-form
                v-if="tab === 'Register'"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>

        <div class="privacy-policy">
          {{ $t('privacy-policy-text') }}
          <a
            href="/terms-and-conditions"
            target="_blank"
          >
            {{ $t('privacy-policy-link') }}
          </a>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
  import '@/styles/overrides.sass'
  import { authComputed } from '@/store/helpers'
  import { mapState } from 'vuex'
  import LoginForm from '@/components/Forms/Customer/LoginForm'
  import RegisterForm from '@/components/Forms/Customer/RegisterForm'
  import NotificationsArea from '@/components/NotificationsArea'

  export default {
    name: 'LoginRegisterComponent',
    components: {
      LoginForm,
      RegisterForm,
      NotificationsArea,
    },
    data: () => ({
      tab: null,
      showLoginModal: true,
      Tabs: [
        'Login', 'Register',
      ],
    }),
    computed: {
      ...authComputed,
      ...mapState('login', ['showLogin']),
      loginDialog: {
        // otan vazw se v-model ena computed property prepei n orizw getter k setter me auton ton tropo
        // getter
        get: function () {
          return this.showLogin || (!this.loggedIn && this.routeIsPrivate)
        },
        // setter
        set: function (newValue) {
          this.$store.commit('login/SET_SHOW_LOGIN', newValue)
        },
      },
    },
    methods: {
      closeCallback: function () {
        // clear offer to save
        this.$store.commit('login/CLEAR_OFFER_TO_SAVE')
        this.loginDialog = false
      },
    },
  }
</script>

<style lang="scss" scoped>
.v-dialog__content--active {
  backdrop-filter: blur(5px);
}
.modal-inner-wrapper {
  background-color: $bgWhite09;
  padding: 15px 50px 5px 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 460px;
  text-align: center;
  border-radius: 5px;

  .intro-message {
    text-align: center;
    color: $goldairLightBlue;
    font-size: 26px;
    font-weight: 300;
    line-height: 1.3;
  }

  .v-tabs{
    margin-top: 20px;
    .v-tab{
      width: 50%;
      text-align: center;
      margin: 0 !important;
      background-color: rgb(175, 202, 230);
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 400;
      color: $goldairBlue !important;
      border-bottom: 1px solid $goldairBlue;

      &.v-tab--active {
        border: 1px solid $goldairBlue;
        border-bottom: 0;
        background-color: #fff;
      }
    }
  }

  .v-tabs-items{
    border: 1px solid rgb(40, 82, 132);
    border-top: 0;
  }

  .privacy-policy{
    font-size: 10px;
    color: $goldairBlue2;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
  }
  .close-modal-button{
    text-align: right;
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .v-card__text{
    padding-top: 24px;
  }
}
.goldair-logo{
  width: 80%;
  margin: 0 auto;
}
</style>
<style lang="scss">
.v-slide-group__prev, .v-slide-group__next {
  display: none !important;
}
</style>
<style lang="scss">
@media all and (max-width: 500px){
  .modal-inner-wrapper {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw;
    height: 100vh;
    transform: none !important;
    max-width: none !important;
  }
}
</style>
