<template>
  <div id="order-form-wrapper">
    <offer-preview-basic
      :oid="oid"
      :show-actions="false"
      :allow-print="false"
    />
    <v-card
      class="mx-auto mt-5 order-form-vcard"
    >
      <v-card-text class="order-form-vcard-text">
        <v-form
          id="order-form"
          ref="orderForm"
          v-model="isFormValid"
        >
          <v-item-group class="form-section customer-details">
            <div
              class="form-section-header"
              @click="customerDetailsShow = !customerDetailsShow"
            >
              Billing Details<v-icon
                v-show="customerDetailsShow"
                class="float-right"
                color="white"
              >
                mdi-chevron-up
              </v-icon><v-icon
                v-show="!customerDetailsShow"
                class="float-right"
                color="white"
              >
                mdi-chevron-down
              </v-icon>
            </div>
            <v-expand-transition>
              <div
                v-if="customerDetailsShow"
                class="form-section-body"
              >
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.Customer.CompanyName"
                    label="Company Name"
                    :rules="getRules(null, true, 'Company Name is required')"
                  />
                  <v-text-field
                    v-model="offer.Customer.CompanyDescription"
                    label="Company Description"
                    :rules="getRules(null, true, 'Company Description is required')"
                  />
                </v-item-group>
                <v-item-group class="fields-group">
                  <v-tooltip
                    v-model="VatTooltip"
                    text="-vat-tooltip"
                    top
                    fixed
                    >
                    <template v-slot:activator="{ props }">
                      <v-text-field
                        v-model="offer.Customer.VatNumber"
                        label="Vat Number"
                        hint="Including Country Code: 'EL123456789'"
                        append-icon="mdi-information"
                        :rules="getRules(null, true, 'Vat Number is required')"
                        @click:append="ShowToolTip()"
                        @change="checkVat()"
                        />
                    </template>
                    <span v-html="$t('vat-tooltip')"></span>
                  </v-tooltip>
                  <v-select
                    v-model="offer.Customer.Country"
                    :items="offer.Customer.CountryItems"
                    item-text="label"
                    item-value="value"
                    label="Country"
                    required
                    :rules="getRules(null, true, 'Country is required')"
                    :disabled="countryDisabled"
                    validate-on-blur
                    :loading="offer.Customer.countryLoading"
                    @change="Update_Offer(false, false)"
                    />
                    <!-- @input="Update_Offer()" -->
                </v-item-group>
                <div v-if="offer.Customer.VatNumber !== ''">
                  <v-item-group class="fields-group">
                    <v-text-field
                    v-model="offer.Customer.TaxActivity"
                    label="Tax Authority"
                    :rules="getRules(null, true, 'Tax Authority is required')"
                    />
                    <!-- v-bind:items="getVatAreasSelectOptions()" -->
                    <v-select
                      v-model="offer.Customer.VatArea"
                      :items="offer.Customer.VatAreaItems"
                      item-text="label"
                      item-value="value"
                      item-code="code"
                      item-vatType="vatType"
                      label="Vat Area"
                      aria-required=""
                      :rules="getRules(null, true, 'Vat Area is required')"
                      :disabled="vatAreaDisabled"
                      :loading="offer.Customer.vatAreaLoading"
                      @input="getVatAreaSelected()"
                      required
                    />
                  </v-item-group>
                </div>
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.Customer.telephone"
                    append-icon="mdi-phone"
                    label="Telephone"
                    :rules="getRules([{ type: 'telephone'}], true, 'Telephone is required')"
                  />
                  <v-text-field
                    v-model="offer.Customer.email"
                    label="Email to receive invoice"
                    append-icon="mdi-email"
                    :rules="getRules([{ type: 'email'}], true, 'Email is required')"
                    required
                  />
                </v-item-group>
              </div>
            </v-expand-transition>
          </v-item-group>

          <v-item-group class="form-section address address-from">
            <div
              class="form-section-header"
              @click="shipFromDetailsShow = !shipFromDetailsShow"
            >
              Ship From (Pickup Address)
              <v-icon
                v-show="shipFromDetailsShow"
                class="float-right"
                color="white"
              >
                mdi-chevron-up
              </v-icon><v-icon
                v-show="!shipFromDetailsShow"
                class="float-right"
                color="white"
              >
                mdi-chevron-down
              </v-icon>
            </div>
            <v-expand-transition>
              <div
                v-if="shipFromDetailsShow"
                class="form-section-body"
              >
                <v-item-group class="fields-group address-selection">
                  <v-select
                    ref="addressFromSelect"
                    v-model="addressFromModel"
                    :items="getSourceAddresses(offer.addressFrom.Country, offer.addressFrom.PostCode)"
                    label="Select from your saved addresses"
                    item-text="label"
                    item-value="value"
                    outlined
                    required
                  >
                    <template v-slot:item="{ item}">
                      <v-list-item
                        @click="addressCallback(item.value, 'source')"
                      >
                        <v-list-item-content>
                          <span>{{ item.label }}</span>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-select>
                </v-item-group>
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.addressFrom.CompanyName"
                    label="Company Name"
                    :rules="getRules(null, true, 'Company Name is required')"
                    required
                  />
                </v-item-group>
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.addressFrom.AddressDetails"
                    label="Street Name/Number"
                    :rules="getRules(null, true, 'Street is required')"
                    required
                  />

                  <v-text-field
                    v-model="offer.addressFrom.City"
                    label="City"
                    :rules="getRules(null, true, 'City is required')"
                    required
                  />
                </v-item-group>
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.addressFrom.PostCode"
                    label="Postal Code"
                    :rules="getRules(null, true, 'Postal Code is required')"
                    required
                    disabled
                  />

                  <v-select
                    v-model="offer.addressFrom.Country"
                    :items="getCountriesSelectOptions()"
                    item-text="label"
                    item-value="value"
                    label="Country"
                    required
                    :rules="getRules(null, true, 'Country is required')"
                    validate-on-blur
                    disabled
                  />
                </v-item-group>
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.addressFrom.ContactPerson"
                    label="Contact Person"
                    :rules="getRules(null, true, 'Contact Person is required')"
                    required
                  />

                  <v-text-field
                    v-model="offer.addressFrom.Email"
                    label="Email"
                    append-icon="mdi-email"
                    :rules="getRules(['email'], true, 'Email is required')"
                    required
                  />

                  <v-text-field
                    v-model="offer.addressFrom.Telephone"
                    label="Telephone"
                    append-icon="mdi-phone"
                    :rules="getRules(['telephone'], true, 'Telephone is required')"
                    required
                  />
                </v-item-group>
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.PickUpReference"
                    label="Pick Up Reference"
                    required
                  />
                  <v-text-field
                    v-model="offer.OnBehalfOf"
                    label="On Behalf Of"
                    required
                  />
                </v-item-group>
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.ContentType"
                    label="Content Type"
                    :rules="getRules(null, true, 'Content Type is required')"
                    required
                  />
                </v-item-group>
                <v-item-group class="fields-group datetime-group">
                  <time-picker
                    label="Working hours From"
                    :rules="getRules(null, true, 'Hour is required')"
                    init-value="09:00"
                    @change="timeChanged($event, 'from', 'from')"
                  />
                  <time-picker
                    label="Working hours To"
                    :rules="getRules(null, true, 'Hour is required')"
                    init-value="18:00"
                    @change="timeChanged($event, 'from', 'to')"
                  />
                  <date-picker
                    label="Pickup Date"
                    :rules="getRules(null, true, 'Pickup Date is required')"
                    @change="dateChanged($event)"
                  />
                </v-item-group>
                <v-checkbox
                  v-model="offer.saveFromAddress"
                  label="Save Address"
                  color="primary"
                />
              </div>
            </v-expand-transition>
          </v-item-group>
          <v-item-group class="form-section address address-to">
            <div
              class="form-section-header"
              @click="shipToDetailsShow = !shipToDetailsShow"
            >
              Ship To (Delivery Address)
              <v-icon
                v-show="shipToDetailsShow"
                class="float-right"
                color="white"
              >
                mdi-chevron-up
              </v-icon><v-icon
                v-show="!shipToDetailsShow"
                class="float-right"
                color="white"
              >
                mdi-chevron-down
              </v-icon>
            </div>
            <v-expand-transition>
              <div
                v-if="shipToDetailsShow"
                class="form-section-body"
              >
                <v-item-group class="fields-group address-selection">
                  <v-select
                    ref="addressToSelect"
                    v-model="addressToModel"
                    :items="getDestinationAddresses(offer.addressTo.Country, offer.addressTo.PostCode)"
                    label="Select from your saved addresses"
                    item-text="label"
                    item-value="value"
                    outlined
                    required
                    @change="addressCallback($event,'destination')"
                    @click="showAddressModal('destination')"
                  />
                </v-item-group>
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.addressTo.CompanyName"
                    label="Company Name"
                    :rules="getRules(null, true, 'Company Name is required')"
                    required
                  />
                </v-item-group>
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.addressTo.AddressDetails"
                    label="Street Name/Number"
                    :rules="getRules(null, true, 'Street is required')"
                    required
                  />

                  <v-text-field
                    v-model="offer.addressTo.City"
                    label="City"
                    :rules="getRules(null, true, 'City is required')"
                    required
                  />
                </v-item-group>
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.addressTo.PostCode"
                    label="Postal Code"
                    :rules="getRules(null, true, 'Postal Code is required')"
                    required
                    disabled
                  />

                  <v-select
                    v-model="offer.addressTo.Country"
                    :items="getCountriesSelectOptions()"
                    item-text="label"
                    item-value="value"
                    label="Country"
                    required
                    :rules="getRules(null, true, 'Country is required')"
                    validate-on-blur
                    disabled
                  />
                </v-item-group>
                <v-item-group class="fields-group">
                  <v-text-field
                    v-model="offer.addressTo.ContactPerson"
                    label="Contact Person"
                    :rules="getRules(null, true, 'Contact Person is required')"
                    required
                  />

                  <v-text-field
                    v-model="offer.addressTo.Email"
                    label="Email"
                    append-icon="mdi-email"
                    :rules="getRules(null, true, 'Email is required')"
                    required
                  />

                  <v-text-field
                    v-model="offer.addressTo.Telephone"
                    label="Telephone"
                    append-icon="mdi-phone"
                    :rules="getRules(null, true, 'Telephone is required')"
                    required
                  />
                </v-item-group>
                <v-item-group class="fields-group datetime-group">
                  <time-picker
                    v-model="offer.addressTo.WorkingHoursFrom"
                    label="Working hours From "
                    :rules="getRules(null, true, 'Hour is required')"
                    init-value="09:00"
                    @change="timeChanged($event, 'to', 'from')"
                  />
                  <time-picker
                    v-model="offer.addressTo.WorkingHoursTo"
                    label="Working hours To "
                    :rules="getRules(null, true, 'Hour is required')"
                    init-value="18:00"
                    @change="timeChanged($event, 'to', 'to')"
                  />
                </v-item-group>
                <v-checkbox
                  v-model="offer.saveToAddress"
                  class="unique-kwnna"
                  label="Save Address"
                  color="primary"
                />
              </div>
            </v-expand-transition>
          </v-item-group>
        </v-form>
        <loader v-show="loader" />
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="primary"
          class="form-btn"
          @click="submitForm(true)"
        >
          {{ submitText }}
        </v-btn>
        <slot name="button-slot" />
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations } from 'vuex'
  import { dataMappers, authComputed } from '@/store/helpers'
  import offerFormMixin from '@/mixins/OfferFormMixin'
  // import AddressForm from '@/components/Forms/Customer/AddressForm'
  import DatePicker from '@/components/Forms/Elements/DatePicker'
  import TimePicker from '@/components/Forms/Elements/TimePicker'
  import Loader from '@/components/Forms/Elements/Loader'
  import { getRules } from '../../store/formValidationRules'
  import recaptchaAuth from '@/services/recaptcha'

  export default {
    name: 'OrderForm',
    components: {
      DatePicker,
      TimePicker,
      Loader,
    },
    mixins: [
      offerFormMixin,
    ],
    props: {
      oid: [Number, String],
      offerData: Object,
      callbacks: Object,
      submitText: {
        type: String,
        default: 'Create',
      },
      addItemText: {
        type: String,
        default: 'Add Item',
      },
    },
    data: () => ({
      customerDetailsShow: true,
      shipFromDetailsShow: true,
      shipToDetailsShow: true,
      isFormValid: false,
      vatAreaDisabled: true,
      countryDisabled: false,
      previewDisabled: false,
      VatTooltip: false,
      VatLoading: false,
      lastItemId: 0,
      itemsArray: [],
      addressFromModel: '',
      addressToModel: '',
      offer: {
        oid: null,
        items: [],
        saveFromAddress: false,
        saveToAddress: false,
        PickUpDate: null,
        PickUpReference: null,
        ContentType: null,
        OnBehalfOf: null,
        addressFrom: {
          City: null,
          Postode: null,
          Country: null,
          CompanyName: null,
          ContactPerson: null,
          Email: null,
          Telephone: null,
          WorkingHoursFrom: null,
          WorkingHoursTo: null,
        },
        addressTo: {
          City: null,
          PostCode: null,
          Country: null,
          CompanyName: null,
          ContactPerson: null,
          Email: null,
          Telephone: null,
          WorkingHoursFrom: null,
          WorkingHoursTo: null,
        },
        Customer: {
          name: '',
          email: '',
          telephone: '',
          CompanyName: '',
          CompanyDescription: '',
          VatNumber: '',
          VatArea: null,
          VatAreaItems: [],
          VatAreaSelected: null,
          TaxActivity: '',
          Country: '',
          CountryItems: [],
          countryLoading: false,
          vatAreaLoading: false,
        },
      },
      addressModal: false,
      newAddressIsDestination: false,
    }),
    computed: {
      ...authComputed,
      ...mapGetters('customer', ['getCustomerAddresses', 'getCustomerAddressOptions', 'getAddressByOid']),
      ...mapGetters('orders', ['getOfferByOid', 'updateOffer', 'action_getMyOffers', 'action_getMyOrders', 'action_updateCurrentOffer']),
      ...mapGetters('apiGeneric', ['getCountries', 'getVatLoading', 'getCountriesSelectOptions', 'getVatAreas', 'getVatAreasSelectOptions', 'getVatConfirmation', 'getPackagingTypesSelectOptions']),
      ...mapActions('apiGeneric', ['action_getVatConfirmation', 'action_setVatLoading', 'action_getVatAreas']),
      ...mapActions('orders', ['action_getOrderById']),
      ...mapMutations('apiGeneric', ['SET_VATLOADING']),
    },
    created () {
      if (this.oid) {
        let obj = {}
        obj = this.getOfferByOid(this.oid)
        if (obj) {
          const mappedData = dataMappers.offerApiToFormObjectMapper(obj)
          this.offer = {
            ...this.offer,
            ...mappedData.data,
          }
          for (let i = 0; i < mappedData.items.length; i++) {
            this.addItem(mappedData.items[i])
          }
          this.isFormValid = true
        }
        // set the fixed value to postcode and country, derived from offer data
        this.offer.addressFrom.PostCode = obj.PostCodeFrom
        this.offer.addressFrom.Country = obj.CountryFrom.Oid
        this.offer.addressTo.PostCode = obj.PostCodeTo
        this.offer.addressTo.Country = obj.CountryTo.Oid
      } else {
        this.addItem()
      }
      this.offer = {
        ...this.offer,
        ...this.offerData,
      }
      this.offer.Customer.CountryItems = this.getCountriesSelectOptions()
    },
    methods: {
      ...mapGetters('customer', ['getCustomerEmail']),
      async submit (token) {
        if (this.$refs.orderForm.validate() && this.callbacks.submit) {
          this.callbacks.submit(this.offer, token)
        }
      },
      getVatAreaSelected () {
        this.setCountry()
        this.Update_Offer(false, false)
      },
      ShowToolTip () {
        this.VatTooltip = !this.VatTooltip
      },
      getCountryCodename (substring) {
        if (substring === 'EL') {
          return 'GR'
        }
        if (substring === 'AT') {
          return 'A'
        }
        if (substring === 'BE') {
          return 'B'
        }
        if (substring === 'BG') {
          return 'BG'
        }
        if (substring === 'CY') {
          return ''
        }
        if (substring === 'CZ') {
          return 'CZ'
        }
        if (substring === 'DE') {
          return 'G'
        }
        if (substring === 'DK') {
          return 'DK'
        }
        if (substring === 'EE') {
          return 'EST'
        }
        if (substring === 'ES') {
          return 'E'
        }
        if (substring === 'FI') {
          return 'FIN'
        }
        if (substring === 'FR') {
          return 'F'
        }
        if (substring === 'HR') {
          return 'HR'
        }
        if (substring === 'HU') {
          return 'H'
        }
        if (substring === 'IE') {
          return ''
        }
        if (substring === 'IT') {
          return ''
        }
        if (substring === 'LT') {
          return 'LT'
        }
        if (substring === 'LU') {
          return 'L'
        }
        if (substring === 'LV') {
          return 'LV'
        }
        if (substring === 'MT') {
          return ''
        }
        if (substring === 'NL') {
          return 'NL'
        }
        if (substring === 'PL') {
          return 'PL'
        }
        if (substring === 'PT') {
          return 'P'
        }
        if (substring === 'RO') {
          return 'RO'
        }
        if (substring === 'SE') {
          return 'S'
        }
        if (substring === 'SI') {
          return 'SLO'
        }
        if (substring === 'SK') {
          return 'SK'
        }
        if (substring === 'XI') {
          return ''
        }
      },
      async setCountry () {
        this.offer.Customer.Country = this.getCountries.find(x => x.Code === this.getCountryCodename(this.offer.Customer.VatNumber.substring(0, 2).toUpperCase())).Oid
      },
      async checkVat (byPassCaptcha = false) {
        const self = this
        let token = null
        if (!byPassCaptcha) {
          // get token conditionally, only if it is obligatory
          token = await recaptchaAuth(self)
        }
        if (this.offer.Customer.VatNumber !== undefined &&
          this.offer.Customer.VatNumber.trim() !== '') {
          this.offer.Customer.countryLoading = true
          this.offer.Customer.vatAreaLoading = true
          this.vatAreaDisabled = true
          this.countryDisabled = true
          const vatTypeReturn = await this.$store.dispatch('apiGeneric/action_getVatConfirmation', this.offer.Customer.VatNumber)
          await this.$store.dispatch('apiGeneric/action_getVatAreas')
          const vatAreas = this.getVatAreasSelectOptions().filter(x => x.vatType === vatTypeReturn.data.VatType)
          this.offer.Customer.VatAreaItems = vatAreas
          if (vatAreas.length > 1) {
            this.vatAreaDisabled = false
            this.setCountry()
          } else {
            this.vatAreaDisabled = true
            this.offer.Customer.VatArea = vatAreas[0].value
            if (this.offer.Customer.VatArea !== 8 &&
              this.offer.Customer.VatArea !== null) {
              this.setCountry()
            }
          }
          this.countryDisabled = false
          this.offer.Customer.countryLoading = false
          this.offer.Customer.vatAreaLoading = false
          this.Update_Offer(false, false)
        }
      },
      async Update_Offer (byPassCaptcha = false, commit = false) {
        this.VatLoading = true
        await this.$store.dispatch('apiGeneric/action_setVatLoading', true, this.VatLoading)
        const self = this
        let token = null
        if (!byPassCaptcha) {
          // get token conditionally, only if it is obligatory
          token = await recaptchaAuth(self)
        }
        const data = this.getOfferByOid(this.offer.oid)
        if (this.offer.Customer.VatArea !== null &&
          this.offer.Customer.Country !== '') {
          data.ClientCountry = this.offer.Customer.Country
          data.ClientVatNo = String(this.offer.Customer.VatNumber)
          data.Items.forEach(item => {
            item.Oid = -1
          })
          const body = {
            ReCaptchaResponse: token,
            Offer: {
              Oid: data.Oid,
            },
            Commit: commit,
            AddressTo: {
              PostCode: data.PostCodeTo,
              Country: {
                Oid: data.CountryTo.Oid,
              },
            },
            AddressFrom: {
              PostCode: data.PostCodeFrom,
              Country: {
                Oid: data.CountryFrom.Oid,
              },
            },
            ClientName: data.ClientName ?? '',
            ClientDescription: data.ClientDescription ?? '',
            ClientEmail: data.ClientEmail ?? '',
            ClientTelephone: data.ClientTelephone ?? '',
            ClientVatNo: data.ClientVatNo ?? '',
            ClientTaxAutority: data.ClientTaxAutority ?? '',
            ClientCountry: {
              Oid: data.ClientCountry,
            },
            ClientVatArea: {
              Oid: this.offer.Customer.VatArea,
            },
            Items: data.Items,
            Oid: -1,
          }
          const order = await this.$store.dispatch('orders/updateOffer', body)
          body.Vat = order.data.Vat
          body.TotalWithVat = order.data.TotalWithVat
          // order.skipCommit = false
          // await this.$store.dispatch('orders/action_getOrderById', order)
          this.previewDisabled = true
          await this.$store.dispatch('orders/action_getMyOffers', body)
          await this.$store.dispatch('orders/action_updateCurrentOffer', body)
          // this.Oid = this.offer.Oid
          this.previewDisabled = false
        }
        this.VatLoading = false
        await this.$store.dispatch('apiGeneric/action_setVatLoading', this.VatLoading)
      },
      addressCallback (newValue, type) {
        this.addressModal = false
        this.newAddressIsDestination = false
        // todo: na fugei to funcyionality gia create address me auton ton tropo
        if (newValue === -1) {
          this.addressModal = true
          this.newAddressIsDestination = type === 'destination'
        } else {
          const addressObj = this.getAddressByOid(newValue)
          if (type === 'destination') {
            this.addressToModel = newValue
            this.offer.addressTo.City = addressObj.Address.City
            this.offer.addressTo.Country = addressObj.Address.Country.Oid
            this.offer.addressTo.Email = addressObj.Address.Email
            this.offer.addressTo.PostCode = addressObj.Address.PostCode
            this.offer.addressTo.AddressDetails = addressObj.Address.AddressDetails
            this.$refs.addressToSelect.blur() // this command closes select options
          } else {
            this.addressFromModel = newValue
            this.offer.addressFrom.City = addressObj.Address.City
            this.offer.addressFrom.Country = addressObj.Address.Country.Oid
            this.offer.addressFrom.Email = addressObj.Address.Email
            this.offer.addressFrom.PostCode = addressObj.Address.PostCode
            this.offer.addressFrom.AddressDetails = addressObj.Address.AddressDetails
            this.$refs.addressFromSelect.blur() // this command closes select options
          }
        }
      },
      showAddressModal (type) {
        if (type === 'source' && this.addressFromModel === -1) {
          this.addressCallback(this.addressFromModel, type)
        } else if (type === 'destination' && this.addressToModel === -1) {
          this.addressCallback(this.addressToModel, type)
        }
      },
      getSourceAddresses (countryId = null, postCode = null) {
        if (this.loggedIn) {
          return this.getCustomerAddressOptions(false, countryId, postCode)
        }
        return []
      },
      getDestinationAddresses (countryId = null, postCode = null) {
        if (this.loggedIn) {
          return this.getCustomerAddressOptions(true, countryId, postCode)
        }
        return []
      },
      initNewAddressData: function (type) {
        const data = {
          isDestination: type === 'destination',
        }
        return data
      },
      getAddressCallbacks () {
        return {
          success: (responsedata) => {
            this.addressModal = false
            this.addressFromModel = responsedata.data.Oid
            const type = (responsedata.data.IsDestination) ? 'destination' : 'source'
            this.addressCallback(responsedata.data.Oid, type)
          },
        }
      },
      dateChanged: function (newValue) {
        this.offer.PickUpDate = newValue
      },
      timeChanged: function (newValue, addressType, hourType) {
        if (addressType === 'from') {
          switch (hourType) {
            case 'from':
              this.offer.addressFrom.WorkingHoursFrom = newValue.displayTime
              break
            case 'to':
              this.offer.addressFrom.WorkingHoursTo = newValue.displayTime
              break
            default:
              break
          }
        } else if (addressType === 'to') {
          switch (hourType) {
            case 'from':
              this.offer.addressTo.WorkingHoursFrom = newValue.displayTime
              break
            case 'to':
              this.offer.addressTo.WorkingHoursTo = newValue.displayTime
              break
            default:
              break
          }
        }
      },
      getRules: getRules, // this is to give access to function getRules from inside template, because the js function is not directly accessible
    },
  }
</script>

<style lang="scss" scoped>
.vat-tooltip {
  background:  green;
  width: 30px;
  display: inline;
  max-width: 100px;
  max-lines: 3;
  word-wrap: normal;
}
.order-form-vcard{
  background-color: rgba(255, 255, 255, 0);
  box-shadow: none !important;
  margin-top: 0 !important;
  margin-left: 0 !important;
  .order-form-vcard-text{
    padding: 0;
    .v-item-group{
      padding: 5px 0;
      &.form-section{
        background-color: $bgWhite09;
        padding: 0;
        margin-top: 5px;

        &.customer-details{
          .fields-group:first-child{
            padding-top: 0;
          }
        }
        .form-section-body{
          padding: 40px 20px 20px;
        }

        .form-section-header{
          background-color: $goldairLightBlue2;
          padding: 5px 20px;
          box-sizing: content-box;
          color: #fff;
          font-weight: 400;
          font-size: 15px;
          cursor: pointer;
        }

        .fields-group{
          display: flex;
          &:not(.address-selection){
            .v-text-field{
              width: 50%;
              // max-width: 50%;
            }
          }
          .v-input {
            padding-top: 0;
            margin-top: 0;
            &:not(:last-child){
              margin-right: 30px;
            }
          }
          &.address-selection{
            padding: 0 !important;
          }
          &.datetime-group{
            padding-top: 0;
            >.row{
              padding: 0;
              margin: 0;
              .col{
                padding: 0 !important;
              }
            }
          }
        }
      }
      &.item-section{
        margin-bottom: 20px;
      }
      .address-group{
        display: flex;
        flex-direction: row;
        .v-select, .v-input:first-child{
          padding-right: 10px;
        }
      }
    }
  }
  .v-card__actions{
    padding: 20px;
    background-color: #fff;
  }
}
</style>
<style lang="scss">
.v-dialog{
  width: fit-content;
  z-index: 9999;
}
.v-input--selection-controls__input {
  margin-right: unset !important;
}
</style>
