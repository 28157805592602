import axios from 'axios'
import Vue from 'vue'
import store from '@/store'

export const apiClient = axios.create({
  baseUrl: Vue.prototype.$backendApiUrl,
  withCredentials: true,
  Headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

apiClient.interceptors.request.use((config) => {
  if (config.params) {
    config.params.lang = store.getters['appGeneral/getLocale']
  } else {
    config.params = {
      lang: store.getters['appGeneral/getLocale'],
    }
  }
  return config
})
