<template>
  <div
    class="loader-wrapper"
  >
    <v-progress-circular
      indeterminate
      color="color"
    />
  </div>
</template>

<script>
  export default {
    name: 'Loader',
    props: {
      color: {
        type: String,
        default: 'primary',
      },
    },
  }
</script>

<style lang="scss" scoped>
.address-card{
  .v-card__text{
    padding: 25px;
  }
}
.loader-wrapper{
  width: 100%;
  height: 100%;
  background-color: $bgWhite08;
  z-index: 9999;
  position: absolute;
  top: 0;
  right: 0;
  .v-progress-circular{
    height: 32px;
    width: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
