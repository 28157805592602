<template>
  <v-container
    class="account"
  >
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>Address #{{ oid }}</v-card-title>
          <v-card-text>
            <p><span class="label">Street: </span>{{ address.Address.AddressDetails }}</p>
            <p><span class="label">City: </span>{{ address.Address.City }}</p>
            <p><span class="label">PostCode: </span>{{ address.Address.PostCode }}</p>
            <p><span class="label">Country: </span>{{ getCountryDisplayNameByOid(address.Address.Country) }}</p>
            <p><span class="label">Telephone: </span>{{ address.Address.Telephone }}</p>
            <p><span class="label">Is Destination: </span>{{ address.Address.IsDestination? 'Yes': 'No' }}</p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'AddressView',
    props: {
      oid: {
        type: [Number, String],
        required: true,
      },
    },
    computed: {
      ...mapGetters('customer', ['getAddressByOid']),
      ...mapGetters('apiGeneric', ['getCountryDisplayNameByOid']),
      address: function () {
        return this.getAddressByOid(this.oid)
      },
    },
  }
</script>
