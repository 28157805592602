<template>
  <v-card
    class="preview-card"
    v-bind="$attrs"
  >
    <v-card-title>
      <strong>{{ getTitle }}</strong>
      <v-spacer />
      <span
        v-if="allowPrint"
        v-ripple
        class="action-link link printer-link"
        @click="generateReport()"
      >
        <v-icon
          color="white"
        >mdi-printer</v-icon> {{ $t('form.print-button') }}
      </span>
      <span
        v-if="!offer.IsExpired && !offer.HasBeenTurnedToOrder"
        v-ripple
        class="action-link link edit-link"
        @click="$router.push({ name: 'Offer/Edit', params: {oid: oid} })"
      >
        <v-icon
          color="white"
        >mdi-pencil</v-icon>{{ $t('form.edit-button') }}</span>
    </v-card-title>

    <v-card-text>
      <div class="basic-info">
        <div class="info-box">
          <span class="label">{{ $t('form.country-from') }}:</span>
          <span class="value">{{ (offer.CountryFrom) ? offer.CountryFrom.DisplayName : '' }}</span>
        </div>
        <div class="info-box">
          <span class="label">{{ $t('form.postalcode-from') }}:</span>
          <span class="value">{{ offer.PostCodeFrom }}</span>
        </div>
        <div class="arrow-box">
          <v-img
            src="@/assets/icons/arrow.png"
            width="25%"
          />
        </div>
        <div class="info-box">
          <span class="label">{{ $t('form.country-to') }}:</span>
          <span class="value">{{ (offer.CountryTo) ? offer.CountryTo.DisplayName : '' }}</span>
        </div>
        <div class="info-box">
          <span class="label">{{ $t('form.postalcode-to') }}:</span>
          <span class="value">{{ offer.PostCodeTo }}</span>
        </div>
      </div>
      <div
        v-if="customerCanPrintOffer"
        class="basic-info"
      >
        <div class="info-box">
          <span class="label">{{ $t('form.client-name') }}:</span>
          <span class="value">{{ offer.ClientName }}</span>
        </div>
        <div class="info-box">
          <span class="label">{{ $t('form.client-telephone') }}:</span>
          <span class="value">{{ offer.ClientTelephone }}</span>
        </div>
      </div>
      <div class="items-wrapper">
        <div
          v-for="(item, index) in offer.Items"
          :key="index"
          class="offer-item"
        >
          <v-card
            class="item-card"
          >
            <v-card-title>{{ getItemTitle(index) }}</v-card-title>
            <v-card-text>
              <div class="info-box">
                <span class="label">{{ $t('packaging-type') }}:</span>
                <span class="value">{{ $t('packaging-types.'+item.PackagingType.Name)+' ('+item.Quantity+')' }}</span>
              </div>
              <div class="info-box">
                <span class="label">{{ $t('width') }}:</span>
                <span class="value">{{ item.Width }}</span>
              </div>
              <div class="info-box">
                <span class="label">{{ $t('height') }}:</span>
                <span class="value">{{ item.Height }}</span>
              </div>
              <div class="info-box">
                <span class="label">{{ $t('length') }}:</span>
                <span class="value">{{ item.Length }}</span>
              </div>
              <div class="info-box">
                <span class="label">{{ $t('weight') }}:</span>
                <span class="value">{{ item.Weight }}</span>
              </div>
            </v-card-text>
          </v-card>
        </div>
      </div>
      <!-- <div class="price-wrapper">
        <span class="label">{{ $t('total-cost') }}:</span> <span class="price">&euro;{{ offer.Total }} </span> <span class="vat">+ {{ $t('vat') }}</span>
        <span class="label">{{ $t('total-cost') }}:</span> <span class="price">&euro;{{ (Math.round((offer.Total + Number.EPSILON) * 100) / 100).toFixed(2) }} </span> <span class="vat">+ {{ $t('vat') }}</span>
      </div> -->
      <div class="price-wrapper">
        <span class="label">{{ $t('cost') }}: </span>
        <span class="price">{{ (Math.round((offer.Total + Number.EPSILON) * 100) / 100).toFixed(2) }}&euro; </span>
      </div>
      <div
        class="price-wrapper"
        style="padding-top: 5px;"
        >
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="VatLoading"
          ></v-progress-circular>
        <!-- <span class="label">{{ $t('vat-empty') }}({{ Math.round(((Math.round((offer.Vat + Number.EPSILON) * 100) / 100).toFixed(2) * 100) / (Math.round((offer.TotalWithVat + Number.EPSILON) * 100) / 100).toFixed(2)) }}%): </span> -->
        <span class="label">{{ $t('vat-empty') }}({{ Math.round((Math.round((offer.Vat + Number.EPSILON) * 100) / 100)/(Math.round((offer.Total + Number.EPSILON) * 100) / 100)*100) }}%): </span>
        <span class="price">{{ (Math.round((offer.Vat + Number.EPSILON) * 100) / 100).toFixed(2) }}&euro; </span>
      </div>
      <div
        class="price-wrapper"
        style="padding-top: 5px;"
        >
        <v-progress-circular
          indeterminate
          color="primary"
          v-if="this.VatLoading"
          ></v-progress-circular>
        <span class="label">{{ $t('total-cost') }}: </span>
        <span class="price">{{ (Math.round((offer.TotalWithVat + Number.EPSILON) * 100) / 100).toFixed(2) }}&euro; </span>
      </div>
    </v-card-text>
    <v-card-actions v-if="showActions">
      <v-spacer />
      <v-btn
        v-if="!offer.IsExpired && !offer.HasBeenTurnedToOrder && !allowPrint"
        color="primary"
        class="form-btn"
        @click="$router.push({ name: 'Order/Create', params: {oid}})"
      >
        {{ $t('create-order-button') }}
      </v-btn>
    </v-card-actions>
    <!-- todo: to print section idanika prepei na ginei entelws automato,
              dld na mhn kanw click apo to current component alla na einai eswteriko tou Print/OfferPrint.vue.
              egw apla 8a prepei na pernaw ta  data-->
    <vue-html2pdf
      v-if="customerCanPrintOffer"
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="export"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      :html-to-pdf-options="{ htmlToPdfOptions: { margin: 0, filename: 'offer-' + oid + '.pdf', image: { type: 'jpeg', quality: 0.98 }, enableLinks: false, html2canvas: { scale: 1, useCORS: true }, jsPDF: { unit: 'px', format: 'a4', orientation: 'portrait' }, } }"
    >
      <section slot="pdf-content">
        <offer-print
          v-if="printData !== null"
          :print-data="printData"
          :title="getTitle"
        />
      </section>
    </vue-html2pdf>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import { authComputed } from '@/store/helpers'
  import VueHtml2pdf from 'vue-html2pdf'
  import OfferPrint from '@/components/Print/OfferPrint'
  import i18n from '@/i18n'

  export default {
    name: 'OfferPreviewBasic',
    components: {
      VueHtml2pdf,
      OfferPrint,
    },
    inheritAttrs: true,
    props: {
      oid: {
        type: [Number, String],
        required: true,
      },
      showActions: {
        type: [Boolean],
        default: true,
      },
      allowPrint: {
        type: [Boolean],
        default: true,
      },
    },
    data () {
      return {
        printData: null,
      }
    },
    computed: {
      ...authComputed,
      ...mapGetters('apiGeneric', ['getPackagingTypeNameById', 'getVatLoading']),
      ...mapGetters('orders', ['getOfferByOid', 'getOrderByOid']),
      ...mapGetters('customer', ['customerCanPrintOffer']),
      ...mapGetters({
        VatLoading: 'apiGeneric/getVatLoading',
      }),
      getTitle: function () {
        return i18n.t('offer-title') + ' #' + this.offer.Oid
      },
      offer () {
        return this.getOfferByOid(this.oid) ?? {}
      },
      // VatLoading () {
      //   return this.$store.apiGeneric.state.vatLoading
      //   // return this.getVatLoading() ?? false
      // },
    },
    // watch: {
    //   VatLoading (newValue, old) {
    //     console.log(`New: ${newValue} / Old: ${old}`)
    //   },
    // },
    created () {
      const redirectPage = 'Account/MyOffers'
      if (!this.offer) {
        this.$store.dispatch('notificationAlerts/addNotification', {
          text: 'Offer with id ' + this.oid + ' does not exist',
          type: 'error',
        },
        )
        this.$router.push({ name: redirectPage })
      }
    },
    methods: {
      ...mapActions('orders', ['createOrder']),
      ...mapGetters('apiGeneric', ['getCountries', 'getPackagingTypes']),
      ...mapGetters('customer', ['getCustomerEmail', 'getCustomerName']),
      generateReport: function () {
        const items = []
        for (let i = 0; i < this.offer.Items.length; i++) {
          items.push({
            packagingType: this.getPackagingTypeNameById(this.offer.Items[i].PackagingType.Oid),
            width: this.offer.Items[i].Width,
            height: this.offer.Items[i].Height,
            length: this.offer.Items[i].Length,
            weight: this.offer.Items[i].Weight,
            quantity: this.offer.Items[i].Quantity,
          })
        }

        this.printData = {
          countryFrom: this.offer.CountryFrom.DisplayName,
          postcodeFrom: this.offer.PostCodeFrom,
          countryTo: this.offer.CountryTo.DisplayName,
          postcodeTo: this.offer.PostCodeTo,
          clientName: this.offer.ClientName,
          clientTelephone: this.offer.ClientTelephone,
          offerModifiedDate: this.offer.ModifiedDate,
          Total: this.offer.Total,
          TotalWithVat: this.offer.TotalWithVat,
          Vat: this.offer.Vat,
          items: items,
        }
        this.$refs.html2Pdf.generatePdf()
      },
      getItemTitle: function (index) {
        const num = (index + 1)
        return i18n.t('offer-item-label') + ' #' + num.toString().padStart(2, '0')
      },
    },
  }
</script>

<style lang="scss" scoped>
.preview-card {
  text-align: center;
  border-radius: 0;
  margin: 0 !important;
  box-shadow: none !important;
  background-color: #fff;

  & > .v-card__title {
    background-color: $goldairYellow;
    color: #fff;
    padding: 5px 20px;
    font-size: 18px;
    box-sizing: content-box;

    .link {
      cursor: pointer;
      font-size: 13px;

      &.printer-link {
        margin-right: 10px;
      }
    }
  }

  & > .v-card__text {
    padding: 30px;
  }

  & > .v-card__actions {
    padding: 0px 30px 20px 30px;
    text-transform: capitalize;
  }

  .v-card__text {
    .basic-info {
      display: flex;
      padding: 0 0 20px 0;

      .info-box {
        width: 20%;
        .value {
          line-height: 1.9em;
        }
      }

      .arrow-box {
        position: relative;
        width: 20%;
        text-align: center;

        .v-icon, .v-image {
          font-size: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    .items-wrapper {
      margin-top: 10px;

      .offer-item {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .item-card {
          border-radius: 0;
          box-shadow: none;

          .v-card__title {
            background-color: $goldairLightBlue2;
            border: none;
            color: #fff;
            padding: 5px 10px;
            font-size: 13px;
          }

          .v-card__text {
            border: 1px solid $goldairLightBlue2;
            border-top: 0 !important;
            display: flex;
            padding: 13px 20px;

            .info-box {
              width: 20%;
              .value {
                line-height: 1.9em;
              }
            }
          }
        }
      }
    }

    .price-wrapper {
      padding: 20px 0 0 0;
      text-align: right;

      .label {
        color: $goldairBlue;
        font-size: 16px;
      }

      .price {
        color: $goldairYellow;
        font-size: 18px;
      }

      .vat {
        color: $goldairYellow;
        font-size: 13px;
      }
    }
    .price-wrapper:nth-child(5) > .price{
      font-weight: bold;
    }
    .info-box {
      .label {
        display: block;
        color: $goldairBlue4;
        font-weight: 400;
        white-space: nowrap;
        font-size: 13px;
      }

      .value {
        display: block;
        color: $goldairBlue;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
}

@media all and (max-width: 750px) {
  #app {
    #main-wrapper {
      .preview-card {
        > .v-card__text {
          padding: 10px;
        }

        .basic-info {
          display: block;
          padding-bottom: 0;

          .info-box {
            display: inline-block;
            width: 50%;
            margin: 0 0 20px 0px;
          }

          .arrow-box {
            display: none !important;
          }
        }

        .items-wrapper {
          .offer-item {
            .item-card {
              .v-card__text {
                padding: 10px;
                display: block;

                .info-box {
                  &:first-child {
                    display: flex;
                    width: 100%;
                    margin-bottom: 10px;

                    .value {
                      margin-left: 10px;
                    }
                  }

                  display: inline-block;
                  width: 50% !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

</style>

<style lang="scss" scoped>
#pdf-content-div {
  background-color: #fff !important;
  padding-right: 30px;
  box-sizing: border-box;
  margin: 20px auto;
  width: 90%;

  .preview-card {
    background-color: #fff !important;
  }
  .v-card__text {
    background-color: #fff !important;
    .basic-info {
      display: flex;
      padding: 0 0 20px 0;

      .info-box {
        width: 20%;
      }

      .arrow-box {
        position: relative;
        width: 20%;
        text-align: center;

        .v-icon, .v-image {
          font-size: 50px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .items-wrapper {
      margin-top: 10px;

      .offer-item {
        &:not(:last-child) {
          margin-bottom: 20px;
        }

        .item-card {
          border-radius: 0;
          box-shadow: none;

          .v-card__title {
            background-color: $goldairLightBlue2;
            border: none;
            color: #fff;
            padding: 5px 10px;
            font-size: 13px;
          }

          .v-card__text {
            border: 1px solid $goldairLightBlue2;
            border-top: 0 !important;
            display: flex;
            padding: 13px 20px;

            .info-box {
              width: 20%;
            }
          }
        }
      }
    }
    .price-wrapper {
      padding: 20px 0 0 0;
      text-align: right;

      .label {
        color: $goldairBlue;
        font-size: 16px;
      }

      .price {
        color: $goldairYellow;
        font-size: 18px;
        font-weight: bold;
      }

      .vat {
        color: $goldairYellow;
        font-size: 13px;
      }
    }
    .info-box {
      .label {
        display: block;
        color: $goldairBlue4;
        font-weight: 400;
        white-space: nowrap;
        font-size: 13px;
      }

      .value {
        display: block;
        color: $goldairBlue;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
}
</style>
