<script>
  import recaptchaAuth from '@/services/recaptcha'

  export default {
    name: 'BasicForm',
    props: {
      byPassCaptcha: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      loader: false,
    }),
    methods: {
      async submitForm (skipLoader) {
        const self = this
        if (skipLoader !== true) {
          this.loader = true
        }
        if (!this.byPassCaptcha) {
          // get token conditionally, only if it is obligatory
          recaptchaAuth(self).then(function (token) {
            self.submit(token)
            // submit must execute 'this.loader = false', to close loader
          })
        } else {
          self.submit(null)
        }
      },
      submit (token) {
        // this function should be overriden by form components
      },
    },
  }
</script>
