<template>
  <span class="info-component">
    <v-icon
      class="info-icon"
      :color="color"
      @click="toggleInfo()"
    >mdi-information-outline</v-icon>
    <dn-tooltip
      v-show="show || (oneTimeShow && !getInfoComponentManuallyClosed())"
      :class="{'drawer-open': drawer, 'menu-mini': mini}"
    >
      <slot />
    </dn-tooltip>
  </span>
</template>

<script>
  import DnTooltip from '@/components/app/DnTooltip'
  import { sync } from 'vuex-pathify'
  import { mapGetters } from 'vuex'
  export default {
    name: 'InfoComponent',
    components: {
      DnTooltip,
    },
    props: {
      color: {
        type: String,
        default: 'white',
      },
      oneTimeShow: {
        type: Boolean,
        default: false,
      },
      v: {
        type: String,
        default: '',
      },
    },
    data: () => {
      return {
        show: false,
      }
    },
    computed: {
      ...sync('app', [
        'drawer',
        'mini',
      ]),
    },
    created () {
      if (this.startOpened) {
        this.show = true
      }
    },
    methods: {
      ...mapGetters('appGeneral', ['getInfoComponentManuallyClosed']),
      toggleInfo: function () {
        this.show = !this.show
        if (this.oneTimeShow && !this.getInfoComponentManuallyClosed()) {
          this.$store.commit('appGeneral/SET_INFO_COMPONENT_MANUALLY_CLOSED', true)
          this.show = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
  .info-component{
    z-index: 999;
    position: relative;
    font-size: 14px;
    .info-icon.v-icon{
      cursor: pointer;
    }
    .dn-tooltip{
      padding: 20px;
      position: absolute;
      bottom: -10px;
      right: -4px;
      margin: 0;
      width: 90vw;
      max-width: 900px;
      @media all and (max-width: 1100px) {
        max-width: 600px;
      }
      @media all and (max-width: 750px) {
        width: 90vw;
        position: fixed !important;
        top: 70px !important;
        left: 50% !important;
        right: auto !important;
        bottom: auto !important;
        height: auto;
        max-height: calc(90vh - 70px);
        overflow: scroll;
        transform: translateX(-50%);
      }
      transform: translate(0, 100%);
      background-color: #fff;
      color: $goldairBlue;
      &.drawer-open{
        width: calc(90vw - 260px);
        &.menu-mini{
          width: calc(90vw - 80px) !important;
        }
      }
      &.menu-mini{
        width: calc(90vw - 80px) !important;
      }
      &:after{
        background-color: #fff;
        bottom: auto;
        top: -5px;
        left: auto;
        right: 10px;
      }
    }
  }
</style>
