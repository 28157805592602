<template>
  <div>
    <material-alert
      v-for="(alert,index) in getNotifications()"
      :id="alert.id"
      :key="index"
      :type="alert.type"
      :dismissible="alert.dismissible"
    >
      {{ alert.text }}
    </material-alert>
  </div>
</template>

<script>
  import MaterialAlert from '@/components/MaterialAlert'
  import { mapGetters } from 'vuex'
  export default {
    name: 'MaterialStatCard',
    components: { MaterialAlert },
    methods: {
      ...mapGetters('notificationAlerts', ['getNotifications']),
    },
  }
</script>
