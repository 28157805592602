<template>
  <v-card
    class="mx-auto mt-5"
  >
    <v-card-text>
      <v-form v-model="isFormValid">
        <v-text-field
          v-model="loginData.email"
          :label="$t('email')"
          :rules="emailRules"
          required
          @keyup.enter="submitForm(true)"
        />
        <v-text-field
          v-model="loginData.password"
          class="password-input"
          :type="showPassword ? 'text' : 'password'"
          :label="$t('password')"
          :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
          :rules="passwordRules"
          required
          @click:append="showPassword = !showPassword"
          @keyup.enter="submitForm(true)"
        />
      </v-form>
      <loader v-show="loader" />
    </v-card-text>
    <v-card-actions>
      <div class="actions-inner-wrapper">
        <v-btn
          id="submitBtn"
          color="primary"
          class="form-btn"
          :disabled="!isFormValid"
          @click="submitForm(true)"
        >
          {{ $t('menu.login') }}
        </v-btn>
        <br>
        <br>
        <a
          class="forgot-password-button"
          @click="showForgotPassword = !showForgotPassword"
        >
          {{ $t('forgot-password') }}
        </a>
      </div>
    </v-card-actions>
    <v-card
      class="forgot-password-section"
    >
      <v-card-text>
        <forgot-password-form
          v-if="showForgotPassword"
        />
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import NProgress from 'nprogress'
  import BasicForm from '@/mixins/BasicForm'
  import Loader from '@/components/Forms/Elements/Loader'
  import ForgotPasswordForm from './ForgotPasswordForm'
  import { requiredRule, emailRule } from '@/store/formValidationRules'
  import recaptchaAuth from '@/services/recaptcha'

  export default {
    name: 'LoginForm',
    components: {
      ForgotPasswordForm,
      Loader,
    },
    mixins: [
      BasicForm,
    ],
    props: {
      callbacks: Object,
    },
    data: () => ({
      isFormValid: false,
      showForgotPassword: false,
      loginData: {
        email: '',
        password: '',
      },
      showPassword: false,
      emailRules: [
        requiredRule('Email is required'),
        emailRule(),
      ],
      passwordRules: [
        requiredRule('Password is required.'),
      ],
    }),
    methods: {
      ...mapActions('auth', ['login']),
      ...mapGetters('login', ['getOfferToSave']),
      async fetchAndWait (callable, errorText) {
        try {
          await callable()
        } catch (err) {
          this.$store.dispatch('notificationAlerts/addNotification', {
            text: errorText,
            type: 'error',
          },
          )
        }
      },
      async submit (token) {
        NProgress.start()
        this.$store.commit('appGeneral/SET_DISABLE_SCREEN', true)
        const { email, password } = this.loginData
        const self = this
        this.$store.dispatch('auth/login', {
          Email: email,
          Password: password,
          ReCaptchaResponse: token,
        })
          .then(async () => {
            // fetch necessary data
            // wait all data to be fetched and commited to vuex, before continue and login. that's why call actions with skipCommit=true (2nd argument) and later commit data manually
            await self.fetchAndWait(async function () {
                                      const response = await self.$store.dispatch('customer/getCustomerDetails', true)
                                      // commit customer details
                                      self.$store.commit('customer/SET_CUSTOMER_DATA', response.data)
                                    },
                                    'An error occurred and user details could not be fetched. Please contact with administrator.')

            await self.fetchAndWait(async function () {
                                      const response = await self.$store.dispatch('apiGeneric/action_getCountries', true)
                                      // commit countries
                                      self.$store.commit('apiGeneric/SET_COUNTRIES', response.data)
                                    },
                                    'An error occurred and user addresses details could not be fetched. Please contact with administrator.')

            await self.fetchAndWait(async function () {
                                      const response = await self.$store.dispatch('apiGeneric/action_getPackagingTypes', true)
                                      // commit packaging types
                                      self.$store.commit('apiGeneric/SET_PACKAGING_TYPES', response.data)
                                    },
                                    'customer/getCustomerDetails', 'An error occurred and user addresses details could not be fetched. Please contact with administrator.')

            const offerData = this.getOfferToSave()
            if (offerData) {
              if (!offerData.email || offerData.email === email) {
                await this.submitOffer(offerData.offer)
                self.$store.commit('login/CLEAR_OFFER_TO_SAVE')
              }
            }
            // this.$router.go() // xwris auto einai pi8ano na mpw se selida pou den prepei (px o prohgoumenos user htan se ena diko tou order -> kanei logout (menei h selida sto background kai to id sto url) -> kanw egw login me ta dika mou creds -> vlepw th selida tou phgoumenou :P)
          })
          .finally(() => {
            NProgress.done() // finish loadbar
            self.loader = false
            this.$store.commit('appGeneral/SET_DISABLE_SCREEN', false)
          })
      },
      async submitOffer (offer, byPassCaptcha = false) {
        const self = this
        let token = null
        if (!byPassCaptcha) {
          // get token conditionally, only if it is obligatory
          token = await recaptchaAuth(self)
        }
        // todo einai duplicated kwdikas apo to src/components/Forms/OfferForm.vue line 363
        const requestPayload = {
          Commit: true,
          ClientName: offer.name,
          ClientEmail: offer.email,
          ClientTelephone: offer.telephone,
          CountryFrom: {
            Oid: offer.countryFrom,
          },
          PostCodeFrom: offer.postcodeFrom,
          CountryTo: {
            Oid: offer.countryTo,
          },
          PostCodeTo: offer.postcodeTo,
          Items: [],
          ReCaptchaResponse: token,
        }

        // ean einai create offer tote den exei oid
        if (offer.oid) {
          requestPayload.Oid = offer.oid
        }

        for (let i = 0; i < offer.items.length; i++) {
          requestPayload.Items.push({
            PackagingType: {
              Oid: offer.items[i].packagingType,
            },
            Width: offer.items[i].width,
            Height: offer.items[i].height,
            Length: offer.items[i].length,
            Weight: offer.items[i].weight,
            Quantity: offer.items[i].itemQty,
          })
        }

        const responseData = await this.$store.dispatch('orders/createOffer', requestPayload)
        if (responseData) {
          const orderData = responseData.data
          if (responseData.status !== 200) {
            self.calculatedCost = null
            self.showDialog = false
            self.$store.dispatch('notificationAlerts/addNotification', {
              text: orderData.CalculatedCostIssues,
              type: 'error',
            },
            )
          } else if (orderData.Oid < 0) {
            self.$store.dispatch('notificationAlerts/addNotification', {
              text: 'Error occurred. Could not save offer.',
              type: 'error',
            },
            )
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })
          } else {
            // fetch offers locally again
            self.showDialog = true
            self.calculatedCost = orderData.Total
            self.$store.dispatch('orders/action_getMyOffers')
            self.$store.dispatch('notificationAlerts/addNotification', {
              text: 'Offer saved successfully',
              type: 'success',
            },
            )
            self.$router.push({ name: 'Account/MyOffers' })
          }
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
#submitBtn{
  text-transform: capitalize;
}
.forgot-password-button{
  cursor: pointer;
}
.v-card__actions{
  padding: 0 !important;
  .actions-inner-wrapper {
    width: 100%;
    text-align: center;
    margin-top: 30px;
    .forgot-password-button{
      display: inline-block;
      margin: 0 auto;
      text-decoration: underline;
      font-size: 11px;
    }
    .v-card__text{
      padding: 0 !important;
    }
  }
}

.v-card{
  margin-top: 0 !important;
  padding: 30px;
  box-shadow: none !important;
  .v-card__text{
    padding: 0 !important;
  }

  &.forgot-password-section{
    padding: 0;
  }
}
</style>
