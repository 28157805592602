import { apiClient } from '../../services/axiosService'
import Vue from 'vue'
import i18n from '@/i18n'

const state = {
  countries: [],
  vatAreas: [],
  vatConfirmed: 0,
  vatLoading: false,
  packagingTypes: [],
}

const getters = {
  getCountries (state) {
    return state.countries
  },
  getVatLoading (state) {
    return state.vatLoading
  },
  getCountryByCode: (state) => function (code) {
    for (let i = 0; i < state.countries.length; i++) {
      if (state.countries[i].Code === code) {
        return state.countries[i]
      }
    }
    return null
},
  getCountryDisplayNameByOid: (state) => function (oid) {
    for (let i = 0; i < state.countries.length; i++) {
      if (Number(state.countries[i].Oid) === Number(oid)) {
        return state.countries[i].DisplayName
      }
    }
    return null
  },
  getVatAreas (state) {
    return state.vatAreas
  },
  getPackagingTypes (state) {
    return state.packagingTypes
  },
  getVatConfirmation (state) {
    return state.vatConfirmed
  },
  getPackagingTypeNameById: (state) => function (id) {
    for (let i = 0; i < state.packagingTypes.length; i++) {
      if (state.packagingTypes[i].Oid === id) {
        return state.packagingTypes[i].Name
      }
    }
    return null
  },
  getCountriesSelectOptions: (state) => function () {
    const countriesObj = state.countries
    const selectOptions = []
    for (let i = 0; i < countriesObj.length; i++) {
      selectOptions.push({
        label: countriesObj[i].DisplayName,
        value: countriesObj[i].Oid,
      })
    }
    return selectOptions
  },
  getVatAreasSelectOptions: (state) => function () {
    const vatAreasObj = state.vatAreas
    const selectOptions = []
    vatAreasObj.forEach(area => {
      selectOptions.push({
        label: area.Name,
        value: area.Oid,
        code: area.Code,
        vatType: area.VatType,
      })
    })
    return selectOptions
  },
  getPackagingTypesSelectOptions: (state) => function () {
    const packagingTypesObj = state.packagingTypes
    const selectOptions = []
    for (let i = 0; i < packagingTypesObj.length; i++) {
      let iconStr = ''
      switch (packagingTypesObj[i].Code) {
        case '1':
          iconStr = require('@/assets/icons/carton.png')
          break
        case '2':
          iconStr = require('@/assets/icons/palette.png')
          break
        case '3':
          iconStr = require('@/assets/icons/barel.png')
          break
        case '4':
          iconStr = require('@/assets/icons/roll.png')
      }

      const labelId = ('packaging-types.' + packagingTypesObj[i].Name).toLowerCase()
      selectOptions.push({
        label: i18n.t(labelId),
        value: packagingTypesObj[i].Oid,
        icon: iconStr,
        code: packagingTypesObj[i].Code,
      })
    }

    return selectOptions.slice().sort(function (a, b) {
      return a.code - b.code
    })
  },
}

const mutations = {
  SET_COUNTRIES (state, data) {
    state.countries = data
  },
  SET_VATLOADING (state, data) {
    state.vatLoading = data
  },
  SET_VATAREAS (state, data) {
    state.vatAreas = data
  },
  SET_VATCONFIRMED (state, data) {
    state.vatConfirmed = data
  },
  SET_PACKAGING_TYPES (state, data) {
    state.packagingTypes = data
  },
}

const actions = {
  async action_getCountries ({ commit }, skipCommit = false) {
    if (skipCommit) {
      return apiClient.get(`${Vue.prototype.$backendApiUrl}/Countries`)
    }
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/Countries`)
    .then((response) => {
      commit('SET_COUNTRIES', response.data)
    })
  },
  action_getVatAreas ({ commit }, skipCommit = false) {
    if (skipCommit) {
      return apiClient.get(`${Vue.prototype.$backendApiUrl}/VatAreas`)
    }
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/VatAreas`)
    .then((response) => {
      commit('SET_VATAREAS', response.data)
    })
  },
  action_getVatConfirmation ({ commit }, vatNumber) {
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/CustomerVatChecker?VatNo=${vatNumber}`)
  },
  action_setVatLoading ({ commit }, skipCommit = false) {
    commit('SET_VATLOADING', skipCommit)
  },
  action_getPackagingTypes ({ commit }, skipCommit = false) {
    if (skipCommit) {
      return apiClient.get(`${Vue.prototype.$backendApiUrl}/PackagingTypes`)
    }
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/PackagingTypes`)
      .then((response) => {
        commit('SET_PACKAGING_TYPES', response.data)
      })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
