<script>
  import { mapGetters } from 'vuex'
  import { dataMappers, authComputed } from '@/store/helpers'
  import BasicForm from '@/mixins/BasicForm'

  export default {
    name: 'OfferFormMixin',
    mixins: [
      BasicForm,
    ],
    props: {
      oid: [Number, String],
      offerData: Object,
    },
    data: () => ({
      itemsArray: [],
      offer: {
        oid: null,
        name: '',
        title: '',
        email: '',
        telephone: '',
        countryFrom: '',
        postcodeFrom: '',
        countryTo: '',
        postcodeTo: '',
        items: [],
      },
    }),
    computed: {
      ...authComputed,
      ...mapGetters('orders', ['getOfferByOid']),
      ...mapGetters('apiGeneric', ['getCountriesSelectOptions', 'getPackagingTypesSelectOptions']),
    },
    created () {
      if (this.oid) {
        let obj = {}
        obj = this.getOfferByOid(this.oid)
        if (obj) {
          const mappedData = dataMappers.offerApiToFormObjectMapper(obj)
          this.offer = {
            ...this.offer,
            ...mappedData.data,
          }
          for (let i = 0; i < mappedData.items.length; i++) {
            this.addItem(mappedData.items[i])
          }
          this.isFormValid = true
        }
      } else {
        this.addItem()
      }
      this.offer = {
        ...this.offer,
        ...this.offerData,
      }
    },
    methods: {
      async submit (e) {
        e.preventDefault()
      },
      removeItem (itemId) {
        for (let i = 0; i < this.offer.items.length; i++) {
          if (this.offer.items[i].id === itemId) {
            this.offer.items.splice(i, 1)
            break
          }
        }
      },
      toggleItem (itemId) {
        for (let i = 0; i < this.offer.items.length; i++) {
          if (this.offer.items[i].id === itemId) {
            this.offer.items[i].show = !(this.offer.items[i].show)
            break
          }
        }
      },
      addItem (newItem) {
        this.lastItemId++
        if (newItem) {
          this.offer.items.push({
            id: this.lastItemId,
            show: true,
            slideOut: false,
            title: 'Item #' + this.lastItemId.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }),
            ...newItem,
          })
        } else {
          this.offer.items.push({
            id: this.lastItemId,
            show: true,
            slideOut: false,
            title: 'Item #' + this.lastItemId.toLocaleString('en-US', {
              minimumIntegerDigits: 2,
              useGrouping: false,
            }),
          })
        }
      },
      makeRequest (action, payload = null) {
        return this.$store.dispatch(action, payload)
      },
    },
  }
</script>
