import { apiClient } from '../../services/axiosService'
import Vue from 'vue'

const state = {
  customer: null,
  customerAddresses: null,
}

const getters = {
  getCustomerData (state) {
    return state.customer
  },
  getCustomerName (state) {
    return (state.customer) ? state.customer.Name : ''
  },
  getCustomerEmail (state) {
    return (state.customer) ? state.customer.Email : ''
  },
  customerCanPrintOffer (state) {
    let canPrintOffer = false
    if (state.customer && state.customer.CanPrintOffer) {
      canPrintOffer = state.customer.CanPrintOffer
    }
    return canPrintOffer
  },
  getCustomerAddresses (state) {
    if (state.customerAddresses) {
      return state.customerAddresses
    }
    return {}
  },
  getAddressByOid: state => function (oid) {
    if (Array.isArray(oid)) {
      const addresses = []
      for (let i = 0; i < oid.length; i++) {
        if (state.customerAddresses[oid[i]]) {
          addresses.push(state.customerAddresses[oid[i]])
        }
      }
      return addresses
    }
    return state.customerAddresses[oid] ?? null
  },
  getAddressesByType: state => function (isDestination) {
    const allAddresses = state.customerAddresses
    const destinationAddresses = []
    for (let i = 0; i < allAddresses.length; i++) {
      if (allAddresses[i].IsDestination) {
        destinationAddresses.push(allAddresses[i])
      }
    }
    if (isDestination) {
      return destinationAddresses
    }
    return allAddresses.filter(x => !destinationAddresses.includes(x))
  },
  getCustomerAddressOptions: (state, getters, rootState, rootGetters) => function (destination, countryId = null, postCode = null) {
    const allAddresses = state.customerAddresses
    const destinationAddressOptions = []
    const sourceAddressOptions = []
    // todo na fugei isws(!) otan ftia3ei o arhs to postcode filtering(na kanei trim ta kena)
    postCode = postCode.replace(/\s+/g, '').toString()
    Object.entries(allAddresses).forEach(
      ([oid, addressData]) => {
        if (((addressData.Address.PostCode === postCode || addressData.Address.PostCode === null || postCode === null) && (addressData.Address.Country.Oid === countryId || countryId === null || addressData.Address.Country.Oid === null))) {
          const selectOption = {
            label: addressData.Address.AddressDetails + ', ' + addressData.Address.City + ', ' + addressData.Address.PostCode + ', ' + addressData.Address.Country.DisplayName,
            value: addressData.Oid,
          }
          if (addressData.IsDestination) {
            destinationAddressOptions.push(selectOption)
          } else {
            sourceAddressOptions.push(selectOption)
          }
        }
      },
    )
    if (destination) {
      return destinationAddressOptions
    }
    return sourceAddressOptions
  },
}

const mutations = {
  SET_CUSTOMER_DATA (state, customerData) {
    state.customer = customerData
  },
  SET_CUSTOMER_ADDRESSES (state, addressesApiObj) {
    var addressesIndexed = null
    if (addressesApiObj) {
      addressesIndexed = {}
      for (let i = 0; i < addressesApiObj.length; i++) {
        addressesIndexed[addressesApiObj[i].Oid] = addressesApiObj[i]
      }
    }
    if (state.customerAddresses) {
      state.customerAddresses = { ...state.customerAddresses, ...addressesIndexed }
    } else {
      state.customerAddresses = { ...{}, ...addressesIndexed } // cast to object
    }
  },
  ADD_SPECIFIC_CUSTOMER_ADDRESS_TO_VUEX (state, addressApiObj) {
    const addressObj = addressApiObj.data
    if (state.customerAddresses) {
      state.customerAddresses[addressObj.Oid] = addressObj
    } else {
      state.customerAddresses = {}
      state.customerAddresses[addressObj.Oid] = addressObj
    }
  },
  CLEAR_CUSTOMER_DATA (state) {
    state.customer = null
  },
  CLEAR_CUSTOMER_ADDRESSES (state) {
    state.customerAddresses = null
  },
}

const actions = {
  getCustomerDetails ({ commit }, skipCommit = false) {
    if (skipCommit) {
      return apiClient.get(`${Vue.prototype.$backendApiUrl}/Customers`)
    }
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/Customers`)
      .then(
        ({ data }) => {
          commit('SET_CUSTOMER_DATA', data)
        },
      )
  },
  action_getCustomerAddresses ({ commit }, data = {}) {
    if (data.skipCommit) {
      return apiClient.get(`${Vue.prototype.$backendApiUrl}/CustomerAddress`, { params: data.params ?? null })
    }
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/CustomerAddress`, { params: data.params ?? null })
      .then(
        ({ data }) => {
          commit('SET_CUSTOMER_ADDRESSES', data)
        },
      )
  },
  action_getCustomerAddressesSummary ({ commit }) {
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/CustomerAddressesSummary`)
  },
  createAddress ({ commit }, data) {
    return apiClient.post(`${Vue.prototype.$backendApiUrl}/CustomerAddress`, data)
  },
  updateAddress ({ commit }, data) {
    return apiClient.put(`${Vue.prototype.$backendApiUrl}/CustomerAddress`, data)
  },
  updateCustomer ({ commit }, data) {
    return apiClient.put(`${Vue.prototype.$backendApiUrl}/Customers`, data)
      .then(
        ({ data }) => {
          commit('SET_CUSTOMER_DATA', data)
        },
      )
  },
  clearCustomer ({ commit }) {
    return commit('SET_CUSTOMER_DATA', null)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
