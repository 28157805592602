<template>
  <v-card
    class="mx-auto mt-5 forgot-password-card"
  >
    <v-card-text>
      <dn-tooltip>To reset your password enter your login email address and click "Reset Password"</dn-tooltip>
      <v-form
        ref="forgotPasswordForm"
        v-model="isFormValid"
      >
        <v-text-field
          v-model="email"
          label="Email"
          :rules="emailRules"
          required
          @keyup.enter="isFormValid && submitForm()"
        />
        <!--          todo auto edw mphke giati otan exei ena mono text-field h forma, tote otan pataw enter kanei refresh th selida gia kapoio logo, anti na kanei submit th form. otan exei >1 fields den sumvainei auto, gia auto kai evala to sugkekrimeno field. prepei omws na vre8ei legit lush kai na fugei entelws to field-->
        <v-text-field
          v-model="email"
          label="Email2"
          :rules="emailRules"
          required
          style="display: none;"
          disabled
          hidden
          @keyup.enter="isFormValid && submitForm()"
        />
      </v-form>
      <loader v-show="loader" />
    </v-card-text>
    <v-card-actions>
      <div class="actions-inner-wrapper">
        <v-btn
          id="submitBtn"
          color="primary"
          class="form-btn"
          :disabled="!isFormValid"
          @click="submitForm(true)"
        >
          Reset Password
        </v-btn>
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions } from 'vuex'
  import BasicForm from '@/mixins/BasicForm'
  import Loader from '@/components/Forms/Elements/Loader'
  import DnTooltip from '@/components/app/DnTooltip'
  import { requiredRule, emailRule } from '@/store/formValidationRules'

  export default {
    name: 'ForgotPasswordForm',
    components: {
      Loader,
      DnTooltip,
    },
    mixins: [
      BasicForm,
    ],
    props: {
      callbacks: Object,
    },
    data: () => ({
      isFormValid: false,
      showPassword: false,
      email: null,
      emailRules: [
        requiredRule('Email is required.'),
        emailRule(),
      ],
    }),
    methods: {
      ...mapActions('auth', ['login']),
      async fetchAndWait (callable, errorText) {
        try {
          await callable()
        } catch (err) {
          this.$store.dispatch('notificationAlerts/addNotification', {
            text: errorText,
            type: 'error',
          },
          )
        }
      },
      submit () {
        const self = this
        this.$store.dispatch('auth/resetPasswordRequest', this.email)
          .then((data) => {
            self.$store.dispatch('notificationAlerts/addNotification', {
              text: 'Request sent successfully. Please check your email',
              type: 'success',
            },
            )
            this.$router.push('/')
          })
          .finally(() => {
            self.loader = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>

.forgot-password-card{
  padding: 0 !important;
  .v-card__text{
    padding: 0;
  }
  .actions-inner-wrapper{
    display: block;
    width: 100%;
    margin-top: 30px;
  }
}
</style>
