import Vue from 'vue'
// import i18n from '@/i18n'

Vue.prototype.$backendBaseUrl = 'http://goldair.datanoesis.gr'
Vue.prototype.$frontBaseUrl = 'http://goldairportal.datanoesis.gr'
Vue.prototype.$backendApiUrl = Vue.prototype.$backendBaseUrl + '/ecomapi/api'
Vue.prototype.$siteKey = '6LcK5VMbAAAAAC1SwmlEnIp41xlgO8_2-dSbwERy' // used for google recaptcha
Vue.prototype.$notificationsDuration = 15000 // how much time (in milliseconds) will the notification messages appear
Vue.prototype.$refreshDataFrequency = 0 // how often the cronjob will request fresh data for orders/offers from the server (in seconds). set 0 to fetch fresh data each time user enters an offer/order view page. Otherwise, the data will be fetched from vues if existing, and will be updated by cron cronjobs enevry N seconds
Vue.prototype.$offerItemsLimit = 4 // set the maximum number of items per offer
Vue.prototype.$defaultPaymentMethod = '' // how often the cronjob will request fresh data for orders/offers from the server (in seconds), could be left empty
Vue.prototype.successUrl = Vue.prototype.$frontBaseUrl + '/payment-success'
Vue.prototype.cancelUrl = Vue.prototype.$frontBaseUrl + '/payment-failure'
Vue.prototype.timeoutUrl = Vue.prototype.$frontBaseUrl + '/payment-failure'
Vue.prototype.errorUrl = Vue.prototype.$frontBaseUrl + '/payment-failure'
Vue.prototype.$orderTrackingUrl = 'https://elogistics.dachser.com/shp2s/home'
Vue.prototype.$paymentMethods = [
  {
    code: 'ByBankDeposit', // 'bank-transfer',
    label: 'payment-methods.bank-transfer-label', // translation id here
    // text: i18n.t('bank-transfer-text'), // this is a description of the payment method, can be left empty
    enabled: true,
  },
  {
    code: 'ByCard', // 'card-payment',
    label: 'payment-methods.card-payment-label', // translation id here
    enabled: true,
  },
]
