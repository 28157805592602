<template>
  <v-btn
    class="form-btn refresh-btn"
    color="primary"
    @click="refresh()"
  >
    <v-icon
      v-show="!isLoading"
      color="white"
    >
      mdi-refresh
    </v-icon>
    <loader v-show="isLoading" />
  </v-btn>
</template>

<script>
  import Loader from './Loader'

  export default {
    name: 'RefreshButton',
    components: { Loader },
    props: {
      action: {
        type: String,
        required: true,
      },
      payload: {
        type: [Object, Boolean, String, Number],
        required: true,
      },
    },
    data: () => ({
      isLoading: false,
    }),
    methods: {
      refresh: function () {
        this.isLoading = true
        this.$store.dispatch(this.action, this.payload)
          .finally(() => {
            this.isLoading = false
          })
      },
    },
  }
</script>
