import { mapGetters } from 'vuex'

export const authComputed = {
  ...mapGetters('auth', ['loggedIn']),
  routeIsPrivate: function () {
    return this.$route.meta.requiresAuth
  },
}

export const dataMappers = {
  offerApiToFormObjectMapper: (apiObject) => {
    const formObject = {}

    formObject.oid = apiObject.Oid
    formObject.name = apiObject.ClientName
    formObject.email = apiObject.ClientEmail
    formObject.telephone = apiObject.ClientTelephone
    formObject.countryFrom = apiObject.CountryFrom.Oid
    formObject.countryTo = apiObject.CountryTo.Oid
    formObject.postcodeFrom = apiObject.PostCodeFrom
    formObject.postcodeTo = apiObject.PostCodeTo
    formObject.Total = apiObject.Total
    formObject.items = []

    const items = []
    for (let i = 0; i < apiObject.Items.length; i++) {
      const itemObj = {}
      itemObj.width = apiObject.Items[i].Width
      itemObj.length = apiObject.Items[i].Length
      itemObj.height = apiObject.Items[i].Height
      itemObj.weight = apiObject.Items[i].Weight
      itemObj.width = apiObject.Items[i].Width
      itemObj.packagingType = apiObject.Items[i].PackagingType.Oid

      // push object to items
      items.push(itemObj)
    }
    return { data: formObject, items: items }
  },

  offerVuexToFormObjectMapper: (apiObject) => {
    const formObject = {}

    formObject.oid = apiObject.Oid
    formObject.name = apiObject.ClientName
    formObject.email = apiObject.ClientEmail
    formObject.telephone = apiObject.ClientTelephone
    formObject.countryFrom = apiObject.CountryFrom.Oid
    formObject.countryTo = apiObject.CountryTo.Oid
    formObject.postcodeFrom = apiObject.PostCodeFrom
    formObject.postcodeTo = apiObject.PostCodeTo
    formObject.items = []

    const items = []
    for (let i = 0; i < apiObject.Items.length; i++) {
      const itemObj = {}
      itemObj.width = apiObject.Items[i].Width
      itemObj.length = apiObject.Items[i].Length
      itemObj.height = apiObject.Items[i].Height
      itemObj.weight = apiObject.Items[i].Weight
      itemObj.width = apiObject.Items[i].Width
      itemObj.packagingType = apiObject.Items[i].PackagingType.Oid

      // push object to items
      items.push(itemObj)
    }
    return { data: formObject, items: items }
  },

  orderVuexAddressToApiMapper: (addressVuexObj) => {
    return {
      AddressDetails: addressVuexObj.Address.AddressDetails,
      PostCode: addressVuexObj.Address.PostCode,
      City: addressVuexObj.Address.City,
      Country: {
        Oid: addressVuexObj.Address.Country,
      },
      Telephone: addressVuexObj.Address.Telephone,
      Email: addressVuexObj.Address.Email,
    }
  },

  /**
   * @param formAddressObj
   * @param type 'from'|'to'
   * @returns {{Email: (null|*), Telephone: (null|*), AddressDetails: *, Country: {Oid: (null|string|*)}, City: (null|*), WorkingHoursTo: string, PostCode: *, WorkingHoursFrom: string, ContactPerson: (null|*)}}
   */
  orderFromAddressToApiMapper: (formAddressObj) => {
    return {
      AddressDetails: formAddressObj.AddressDetails,
      PostCode: formAddressObj.PostCode,
      City: formAddressObj.City,
      Country: {
        Oid: formAddressObj.Country,
      },
      Telephone: formAddressObj.Telephone,
      Email: formAddressObj.Email,
      ContactPerson: formAddressObj.ContactPerson,
      WorkingHoursFrom: helperFunctions.encodeTimeForApi(formAddressObj.WorkingHoursFrom),
      WorkingHoursTo: helperFunctions.encodeTimeForApi(formAddressObj.WorkingHoursTo),
    }
  },
  orderStatusCodeToStringMapper: (code) => {
    if (code === 0) {
      return 'Pending'
    }
    if (code === 1) {
      return 'Processing'
    }
    if (code === 2) {
      return 'On Collection'
    }
    if (code === 3) {
      return 'Collected'
    }
    return 'Pending'
  },

  addressVuexToFormObjectMapper: (vuexObject) => {
    const formObject = {}
    formObject.addressDetails = vuexObject.Address.AddressDetails
    formObject.postCode = vuexObject.Address.PostCode
    formObject.country = vuexObject.Address.Country
    formObject.city = vuexObject.Address.City
    formObject.email = vuexObject.Address.Email
    formObject.telephone = vuexObject.Address.Telephone
    formObject.isDestination = vuexObject.IsDestination
    return formObject
  },
}

export const helperFunctions = {
  encodeTimeForApi: (time) => '2000-01-01T' + time + ':00.00',
  decodeTimeFromApi: (encodedTime) => encodedTime.replace('2000-01-01T', '').replace(':00', ''),
  encodeDateForApi: (date) => date + 'T00:00:00.00',
  decodeDateFromApi: (encodedDate) => encodedDate.replace('T00:00:00', ''),
}
