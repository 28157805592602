import { render, staticRenderFns } from "./LoginRegisterComponent.vue?vue&type=template&id=4f017673&scoped=true&"
import script from "./LoginRegisterComponent.vue?vue&type=script&lang=js&"
export * from "./LoginRegisterComponent.vue?vue&type=script&lang=js&"
import style0 from "./LoginRegisterComponent.vue?vue&type=style&index=0&id=4f017673&lang=scss&scoped=true&"
import style1 from "./LoginRegisterComponent.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./LoginRegisterComponent.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f017673",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VDialog,VImg,VTab,VTabItem,VTabs,VTabsItems})
