import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins'
import store from './store'
import { sync } from 'vuex-router-sync'
import globals from './store/global_variables'
import filters from './filters'
import { apiClient } from '@/services/axiosService'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VueCrontab from 'vue-crontab'
import i18n from './i18n'
import compareVersions from 'compare-versions'

Vue.config.productionTip = false

sync(store, router)

// For more options see below
Vue.use(VueReCaptcha, { siteKey: Vue.prototype.$siteKey })

// install filters
filters.install(Vue)

Vue.use(VueCrontab)

new Vue({
  router,
  vuetify,
  store,
  created () {
    const userString = localStorage.getItem('user')
    if (userString) {
      const userData = JSON.parse(userString)
      this.$store.commit('auth/SET_USER_DATA', userData)
    }

    apiClient.interceptors.response.use(
      response => {
        return response
      },
      (error, response) => {
        let errorMsg = error
        if (error.response.data.ErrorDescription) {
          errorMsg = error.response.data.ErrorCode + ': ' + error.response.data.ErrorDescription
        } else if (error.response.data.Message) {
          errorMsg = error.response.data.Message
        }
        this.$store.dispatch('notificationAlerts/addNotification', {
            text: errorMsg,
            type: 'error',
          },
        ).then(() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
        })
        const url = new URL(error.response.config.url)
        if (
          error.response.status === 401 &&
          // requests that should not logout should be appanded to the if-condition here
          !(
            (url.pathname === '/api/Customers' && error.response.config.method === 'post') ||
            (url.pathname === '/api/Login' && error.response.config.method === 'post')
          )
        ) {
          this.$store.dispatch('auth/logout')
        }
        return Promise.reject(error)
      },
    )
  },
  i18n,
  render: h => h(App),
}).$mount('#app')

const storedVersion = store.getters['appGeneral/getAppVersion']
if (!storedVersion) {
  store.commit('appGeneral/SET_APP_VERSION', process.env.VUE_APP_VERSION)
} else if (compareVersions(storedVersion, process.env.VUE_APP_VERSION) !== 0) {
  // new version detected...clear localstorage and reload page
  window.localStorage.clear()
  router.push('/')
  // window.location.reload() // do not use "store.dispatch('auth/logout')" because it contains window.realod() and produces infite reloads
  const href = window.location.href // window.location.reload(true) is deprecated, also true parameter that forces hard reload works only on firefox
  window.location.href = href // https://stackoverflow.com/a/22841435
}
