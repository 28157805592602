export function emailRule (msg = 'E-mail must be valid') {
  return value => /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || msg
}

export function requiredRule (msg = 'This field is required.') {
  return function (value) { return !!value || msg }
}

export function passwordRegexRule (allowEmpty = false, msg = 'Password must be at least 8 characters and contain least one digit, uppercase letter, lowercase letter and one of the following symbols !,@,*,&,^,%,#') {
  // this is the regex tested at regex101: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
  if (allowEmpty) {
    return function (value) { return (/^$/.test(value)) || /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) || msg }
  }
    return function (value) { return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(value) || msg }
}

export function telephoneRegexRule (msg = 'Telephone is not accepted') {
    return function (value) {
      return (/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s.0-9]*$/.test(value)) || msg
    }
}

export function requiredRule2 (msg = 'This field is required 2.') {
  return function (value) { return !!value || msg }
}

/**
 * ruleTypes: can be telephone|email. You may expand functionality with any other value
 * */
export function getRules (ruleTypes = [], required = true, requiredMsg = null) {
  const rulesObj = []
  if (required) {
    rulesObj.push(requiredRule(requiredMsg))
  }
  if (Array.isArray(ruleTypes)) {
    ruleTypes.forEach(function (ruleTypeObj) {
      switch (ruleTypeObj.type) {
        case 'telephone':
          if ('msg' in ruleTypeObj) {
            rulesObj.push(telephoneRegexRule(ruleTypeObj.msg))
          } else {
            rulesObj.push(telephoneRegexRule())
          }
          break
        case 'email':
          if ('msg' in ruleTypeObj) {
            rulesObj.push(emailRule(ruleTypeObj.msg))
          } else {
            rulesObj.push(emailRule())
          }
          break
      }
    })
  }
  return rulesObj
}
