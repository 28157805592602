import { apiClient } from '../../services/axiosService'
import Vue from 'vue'
import { orders } from './index'

const state = {
  myOrders: [],
  myOffers: [],
  successPageAllowedAccess: [], // used to determine access to success page
  errorPageAllowedAccessCounter: 0, // used to determine access to error page
}

const getters = {
  getMyOrders (state) {
    return state.myOrders
  },
  getOrderByOid: (state) => function (oid) {
    if (Array.isArray(oid)) {
      const orders = []
      for (let i = 0; i < oid.length; i++) {
        if (state.myOrders && state.myOrders[oid[i]]) {
          orders.push(state.myOrders[oid[i]])
        }
      }
      return orders
    }
    if (state.myOrders && state.myOrders[oid]) {
      return state.myOrders[oid]
    }
    return null
  },
  getOrderByRefCode: (state) => function (refCode) {
    // todo sunexeia edw, ulopoihsh kai check sthn cancelCallback sto /home/dimitris/vue/goldair/src/store/modules/api-payment.js
      for (let i = 0; i < state.myOrders.length; i++) {
        if (state.myOrders.Payments.length && state.myOrders.Payments[0] && state.myOrders.Payments[0].RefCode === refCode) {
          return state.myOrders[i]
        }
      }
      return null
  },
  getMyOffers (state) {
    return state.myOffers
  },
  getOfferByOid: (state) => function (oid) {
    if (Array.isArray(oid)) {
      const offers = []
      for (let i = 0; i < oid.length; i++) {
        if (state.myOffers[oid[i]]) {
          offers.push(state.myOffers[oid[i]])
        }
      }
      return offers
    }
    return state.myOffers[oid] ?? null
  },
  successPageAllowedForOid: (state) => function (oid) {
    oid = parseInt(oid)
    for (let i = 0; i < state.successPageAllowedAccess.length; i++) {
      if (state.successPageAllowedAccess[i] === oid) {
        return true
      }
    }
    return false
  },
  getSuccessLastOid: (state) => function (oid) {
    oid = parseInt(oid)
    if (state.successPageAllowedAccess.length) {
      return state.successPageAllowedAccess[state.successPageAllowedAccess.length - 1]
    }
    return -1
  },
  errorPageAllowed: (state) => function () {
    return state.errorPageAllowedAccessCounter > 0
  },
  getOrderPaymentType: (state) => function (oid) {
    if (Object.keys(state.myOrders).includes(oid.toString())) { // cast to string, because keys are strings
      return state.myOrders[oid].PaymentType
    }
    return null
  },
  orderIsPaid: (state) => function (oid) {
    if (Object.keys(state.myOrders).includes(oid.toString()) && state.myOrders[oid].Payments[0] !== undefined) { // cast to string, because keys are strings
      return state.myOrders[oid].Payments[0].Paid
    }
    return false
  },
  getOrderPaymentRefCode: (state) => function (oid) {
    if (Object.keys(state.myOrders).includes(oid.toString()) && state.myOrders[oid].Payments[0] !== undefined) { // cast to string, because keys are strings
      return state.myOrders[oid].Payments[0].RefCode
    }
    return null
  },
}

const mutations = {
  UPDATE_OFFER (state, orderObj) {
    state.myOffers[orderObj.Offer.Oid].Vat = orderObj.Vat
    state.myOffers[orderObj.Offer.Oid].TotalWithVat = orderObj.TotalWithVat
  },
  SET_MY_ORDERS (state, ordersApiObj) {
    var ordersIndexed = null
    var orders = ordersApiObj.data
    if (orders) {
      ordersIndexed = {}
      for (let i = 0; i < orders.length; i++) {
        ordersIndexed[orders[i].Oid] = orders[i]
      }
    }
    if (state.myOrders) {
      state.myOrders = { ...state.myOrders, ...ordersIndexed }
    } else {
      state.myOrders = { ...{}, ...ordersIndexed }
    }
  },
  ADD_SPECIFIC_ORDER_TO_VUEX (state, orderApiObj) {
    const orderObj = orderApiObj.data
    if (state.myOrders) {
      state.myOrders[orderObj.Oid] = orderObj
    } else {
      state.myOrders = {}
      state.myOrders[orderObj.Oid] = orderObj
    }
  },
  SET_MY_OFFERS (state, offersApiObj) {
    var offersIndexed = null
    const offers = offersApiObj.data
    if (offers) {
      offersIndexed = {}
      for (let i = 0; i < offers.length; i++) {
        offersIndexed[offers[i].Oid] = offers[i]
      }
    }
    if (state.myOffers) {
      state.myOffers = { ...state.myOffers, ...offersIndexed }
    } else {
      state.myOffers = { ...{}, ...offersIndexed }
    }
  },
  ADD_SPECIFIC_OFFER_TO_VUEX (state, offerApiObj) {
    const offerObj = offerApiObj.data
    if (state.myOffers) {
      state.myOffers[offerObj.Oid] = offerObj
    } else {
      state.myOffers = {}
      state.myOffers[offerObj.Oid] = offerObj
    }
  },
  CLEAR_ORDERS (state) {
    state.myOrders = null
  },
  CLEAR_OFFERS (state) {
    state.myOffers = null
  },
  SUCCESS_PAGE_ALLOW_ACCESS_ADD_OID (state, oid) {
    oid = parseInt(oid)
    state.successPageAllowedAccess.push(oid)
    var newData = state.successPageAllowedAccess
    state.successPageAllowedAccess = newData.filter((item, i, ar) => ar.indexOf(item) === i)
  },
  SUCCESS_PAGE_ALLOW_ACCESS_REMOVE_OID (state, oid) {
    oid = parseInt(oid)
    state.successPageAllowedAccess = state.successPageAllowedAccess.filter(function (value, index, arr) {
      return value !== oid
    })
  },
  ERROR_PAGE_INCREMENT_COUNTER (state) {
    state.errorPageAllowedAccessCounter++
  },
  ERROR_PAGE_DECREASE_COUNTER (state) {
    if (state.errorPageAllowedAccessCounter > 0) {
      state.errorPageAllowedAccessCounter--
    }
  },
}

const actions = {
  action_updateCurrentOffer ({ commit }, data) {
    commit('UPDATE_OFFER', data)
  },
  action_getMyOrders ({ commit }, data = {}) {
    if (data.skipCommit) {
      return apiClient.get(`${Vue.prototype.$backendApiUrl}/Orders`, { params: data.params ?? null })
    }
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/Orders`, { params: data.params ?? null })
      .then((response) => {
        commit('SET_MY_ORDERS', response)
      })
  },
  action_updateOrdersInVuex ({ dispatch, getters }, data = {}) {
    const vuexOrders = getters.getMyOrders
    if (vuexOrders && Object.keys(vuexOrders).length) {
      const orderIDs = Object.keys(vuexOrders)
      for (let i = 0; i < orderIDs.length; i++) {
        dispatch('action_getOrderById', { orderId: orderIDs[i] })
      }
    }
  },
  async action_getOrderById ({ commit }, data = {}) {
    if (data.skipCommit) {
      return apiClient.get(`${Vue.prototype.$backendApiUrl}/Orders/${data.orderId}`)
    }
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/Orders/${data.orderId}`)
      .then((response) => {
        commit('ADD_SPECIFIC_ORDER_TO_VUEX', response)
      })
  },
  async action_getOrdersSummary ({ commit }, data = {}) {
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/OrdersSummary`)
  },
  action_getMyOffers ({ commit }, data = {}) {
    if (data.skipCommit) {
      return apiClient.get(`${Vue.prototype.$backendApiUrl}/Offers`, { params: data.params ?? null })
    }
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/Offers`, { params: data.params ?? null })
      .then((response) => {
        commit('SET_MY_OFFERS', response)
      })
  },
  async action_getOfferById ({ commit }, data = {}) {
    if (data.skipCommit) {
      return apiClient.get(`${Vue.prototype.$backendApiUrl}/Offers/${data.offerId}`)
    }
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/Offers/${data.offerId}`)
      .then((response) => {
        commit('ADD_SPECIFIC_OFFER_TO_VUEX', response)
      })
  },
  action_updateOffersInVuex ({ dispatch, getters }, data = {}) {
    const vuexOffers = getters.getMyOffers
    if (Object.keys(vuexOffers).length) {
      const offerIDs = Object.keys(vuexOffers)
      for (let i = 0; i < offerIDs.length; i++) {
        dispatch('action_getOfferById', { offerId: offerIDs[i] })
      }
    }
  },
  async action_getOffersSummary ({ commit }, data = {}) {
    return apiClient.get(`${Vue.prototype.$backendApiUrl}/OffersSummary`)
  },

  createOrder ({ commit, dispatch }, payload) {
    return apiClient.post(`${Vue.prototype.$backendApiUrl}/Orders`, payload)
  },

  createOffer ({ commit, dispatch }, payload) {
    return apiClient.post(`${Vue.prototype.$backendApiUrl}/Offers`, payload)
  },

  updateOffer ({ commit, dispatch }, payload) {
    return apiClient.post(`${Vue.prototype.$backendApiUrl}/Orders`, payload)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
