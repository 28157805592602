<template>
  <v-card
    width="400"
    class="mx-auto mt-5"
  >
    <v-card-text class="address-card-text">
      <v-form v-model="isFormValid">
        <v-text-field
          v-model="address.addressDetails"
          label="Address Details"
          :rules="addressDetailsRules"
          validate-on-blur
          required
        />
        <v-text-field
          v-model="address.postCode"
          label="Postcode"
          :rules="postCodeRules"
          validate-on-blur
          required
        />
        <v-select
          v-model="address.country.Oid"
          :items="getCountriesSelectOptions()"
          item-text="label"
          item-value="value"
          label="Select Country"
          outlined
          required
          :rules="countryRules"
        />
        <v-text-field
          v-model="address.city"
          label="City"
          :rules="cityRules"
          validate-on-blur
          required
        />
        <v-text-field
          v-model="address.email"
          label="Email"
          :rules="emailRules"
          validate-on-blur
          required
        />
        <v-text-field
          v-model="address.telephone"
          label="Telephone"
          :rules="telephoneRules"
          validate-on-blur
          required
        />
        <v-checkbox
          v-model="address.isDestination"
          label="Is Destination"
          :disabled="this.formMeta.disabled && this.formMeta.disabled.indexOf('isDestination') !== -1"
        />
      </v-form>
      <loader v-show="loader" />
    </v-card-text>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        class="form-btn"
        :disabled="!isFormValid"
        @click="submitForm"
      >
        {{ submitText }}
      </v-btn>
      <slot name="button-slot" />
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Loader from '@/components/Forms/Elements/Loader'
  import BasicForm from '@/mixins/BasicForm'
  import { dataMappers } from '@/store/helpers'
  import { requiredRule, emailRule, telephoneRegexRule } from '@/store/formValidationRules'

  export default {
    name: 'AddressForm',
    mixins: [
      BasicForm,
      Loader,
    ],
    props: {
      oid: {
        type: [Number, String],
        default: null,
      },
      addressData: Object,
      formMetaData: Object,
      callbacks: Object,
      formType: {
        type: String,
        default: 'create',
      },
      submitText: {
        type: String,
        default: 'Submit',
      },
    },
    data: () => ({
      isFormValid: false,
      allowMakeOrder: true,
      lastItemId: 0,
      itemsArray: [],
      address: {
        addressDetails: '',
        postCode: '',
        country: '',
        city: '',
        email: '',
        telephone: '',
        isDestination: false,
      },
      formMeta: {},
      addressDetailsRules: [
        requiredRule('Address Details are required.'),
      ],
      countryRules: [
        requiredRule('Country is required.'),
      ],
      postCodeRules: [
        requiredRule('Postcode is required.'),
      ],
      cityRules: [
        requiredRule('City is required.'),
      ],
      emailRules: [
        requiredRule('Email is required.'),
        emailRule(),
      ],
      telephoneRules: [
        requiredRule('Telephone field is required.'),
        telephoneRegexRule(),
      ],
    }),
    computed: {
      ...mapGetters('apiGeneric', ['getCountriesSelectOptions']),
      ...mapGetters('customer', ['getAddressByOid']),
    },
    created () {
      if (this.oid) {
        const addressObj = this.getAddressByOid(this.oid)
        if (addressObj) {
          this.address = dataMappers.addressVuexToFormObjectMapper(addressObj)
        }
      }
      this.address = {
        ...this.address,
        ...this.addressData,
      }
      this.formMeta = {
        ...this.formMeta,
        ...this.formMetaData,
      }
    },
    methods: {
      async submit (token) {
        const requestPayload = {
          Address: {
            AddressDetails: this.address.addressDetails,
            PostCode: this.address.postCode,
            City: this.address.city,
            Country: {
              Oid: this.address.country.Oid,
            },
            Telephone: this.address.telephone,
            Email: this.address.email,
          },
          IsDestination: this.address.isDestination,
          ReCaptchaResponse: token,
        }

        let actionName = 'customer/createAddress'
        let successMsg = 'Address created successfully'
        if (this.formType === 'update') {
          actionName = 'customer/updateAddress'
          successMsg = 'Address with id:' + this.oid + ' updated successfully'
          requestPayload.Oid = this.oid
        }

        const self = this
        this.$store.dispatch(actionName, requestPayload)
          .then((responseData) => {
            // fetch orders locally again
            self.$store.dispatch('notificationAlerts/addNotification', {
              text: successMsg,
              type: 'success',
            },
            )
            self.$store.dispatch('customer/action_getCustomerAddresses').then((response) => {
              if (self.callbacks.success) {
                self.callbacks.success(responseData)
              }
            })
          })
          .finally(() => {
            self.loader = false
          })
      },
    },
  }
</script>

<style lang="scss" scoped>
      .address-card-text{
        padding-top: 25px !important;
      }
</style>
